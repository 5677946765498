const BASE_URL = "https://trade.affyclouditsolutions.com/api";
export const IMG_URL = "https://trade.affyclouditsolutions.com/";

export const LOGIN_URL = BASE_URL + "/login";
export const LOGOUT_URL = BASE_URL + "/logout";
export const PROFILE_DETAILS = BASE_URL + "/profile";

// Superadmin
export const CREATE_COMPANY = BASE_URL + "/superadmin/company-register";
export const SHOW_NUMBER_SUPERAADMIN =
  BASE_URL + "/superadmin/all-companies-number-data";
export const SHOW_NUMBER_SUPERAADMIN_BY_COMPANY =
  BASE_URL + "/superadmin/one-company-number-data/:id";
// export const SHOW_ALL_COMPANY = BASE_URL + "/superadmin/company-with-employee-list/1";
export const SHOW_ALL_COMPANY =
  BASE_URL + "/superadmin/companies-with-employees-list";
export const COMPANY_BY_ID =
  BASE_URL + "/superadmin/company-with-employees/:id";

export const ACTIVE_DEACTIVE_COMPANY =
  BASE_URL + "/superadmin/company-active/:id";

// Company
export const CREATE_EMPLOYEE = BASE_URL + "/company/employee-register";
export const SHOW_COMPANY_EMPLOYEE = BASE_URL + "/company/employees-list";
export const SEND_CUSTOMER_LINK = BASE_URL + "/send-registration-link";
export const COMPANY_ALL_CUSTOMER = BASE_URL + "/company-customer-list";
export const ASSIGN_EMPLOYEE_CUSTOMER = BASE_URL + "/customer-employee-update";
export const TRADE_REPORTS_COMPANY = BASE_URL + "/company/get_order_history";
export const CUSTOMER_PLAN = BASE_URL + "/company/update-customer-plan";
export const COMPANY_NUMBER_DATA = BASE_URL + "/company/number-data";
export const COMPANY_PROFILE = BASE_URL + "/company-profile";
export const COMPANY_PROFILE_UPDATE =
  BASE_URL + "/company/company-profile-update";
export const COMPANY_PASSWORD_UPDATE = BASE_URL + "/company/update-password";
export const COMPANY_LOGO_UPDATE = BASE_URL + "/company/update-logo";
export const CREATE_COMPANY_SUBADMIN = BASE_URL + "/company/subadmin-register";
export const GET_COMPANY_SUBADMIN = BASE_URL + "/company/subadmin-list";

// Employee
export const ALL_CUSTOMER_EMPLOYEE = BASE_URL + "/employee/customer-info";
export const CREATE_GROUP = BASE_URL + "/create-group";
export const ADD_CUSTOMER_TO_GROUP = BASE_URL + "/add-customer";
export const SHOW_GROUP_WITH_CUSTOMER = BASE_URL + "/groups-and-customer";
export const TRADE_BY_GROUP = BASE_URL + "/empl/execute-trade";
export const REMOVE_CUSTOMER_FROM_GROUP = BASE_URL + "/delete-customer";
export const DELETE_GROUP = BASE_URL + "/delete-group";
export const TRADE_SAVE_HISTORY = BASE_URL + "/trade-history";
export const TRADE_REPORTS_EMPLOYEE = BASE_URL + "/employee/get_order_history";
export const EDIT_LOT_GROUP = BASE_URL + "/update-customer-lot-size";
export const EMPLOYEE_PROFILE = BASE_URL + "/employee-profile";
export const EMPLOYEE_PASSWORD_UPDATE = BASE_URL + "/employee/update-password";
export const CUSTOMER_DEACTIVE = BASE_URL + "/customer/permanent/logout";

// Subadmin
export const REGISTER_SUBADMIN = BASE_URL + "/subadmin/subadmin-register";
export const VIEW_SUBADMIN = BASE_URL + "/subadmin/subadmin-list";
export const SUBADMIN_PROFILE = BASE_URL + "/subadmin-profile";
export const SUBADMIN_PASSWORD = BASE_URL + "/subadmin/update-password";
export const SEND_REGISTRATION_CUSTOMER =
  BASE_URL + "/subadmin/send-registration-link";
export const CUSTOMER_ADMIN_LIST = BASE_URL + "/subadmin/customer-list";
export const SUBADMIN_LINK_GENERATE = BASE_URL + "/subadmin/generate-link";
export const SHOW_LEADS = BASE_URL + "/subadmin/customerleads";
