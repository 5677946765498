import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ALL_CUSTOMER_EMPLOYEE, CUSTOMER_DEACTIVE } from "../../Config/api";

export default function EmployeeCustomerList() {
  const [customersList, setCustomersList] = useState([]);

  useEffect(() => {
    fetchAllCustomers();
  }, []);

  const fetchAllCustomers = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await fetch(ALL_CUSTOMER_EMPLOYEE, requestOptions);
      if (response.ok) {
        const data = await response.json();
        setCustomersList(data.customers);
        console.log(data.customers);
      } else {
        toast.error("Failed to fetch customers");
        console.error("Failed to fetch customers:", response.statusText);
      }
    } catch (error) {
      toast.error("Error fetching customers");
      console.error("Error fetching customers:", error);
    }
  };

  // sorting
  const [searchQuery, setSearchQuery] = useState("");
  const [sortPlatform, setSortPlatform] = useState("");
  const [sortPlan, setSortPlan] = useState("");
  const [sortStatus, setSortStatus] = useState("");
  const [fundsData, setFundsData] = useState({});
  const filteredCustomers = customersList
    .filter((customer) =>
      customer.customer_name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter((customer) =>
      sortPlatform ? customer.customer_platformType === sortPlatform : true
    )
    .filter((customer) => (sortPlan ? customer.plan === sortPlan : true))
    .filter((customer) =>
      sortStatus ? customer.customer_status === sortStatus : true
    );

  useEffect(() => {
    const fetchFundsData = async () => {
      const updatedFundsData = {};
      for (const customer of customersList) {
        try {
          let result = "No Funds";

          if (customer.customer_platformType === "AngelOne") {
            result = await angelFunds(
              customer.angel_api_key,
              customer.angel_jwt_token
            );
          } else if (customer.customer_platformType === "Upstox") {
            result = await upstoxFunds(customer.upstox_jwt_token);
          }

          updatedFundsData[customer.customer_id] = result;
        } catch (error) {
          console.error(
            "Error fetching funds for customer:",
            customer.customer_name,
            error
          );
          updatedFundsData[customer.customer_id] = "Error fetching funds";
        }
      }
      setFundsData(updatedFundsData);
    };

    fetchFundsData();
  }, [customersList]);

  const angelFunds = async (api_key, jwt_token) => {
    try {
      const response = await fetch(
        "https://apiconnect.angelbroking.com/rest/secure/angelbroking/user/v1/getRMS",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwt_token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-UserType": "USER",
            "X-SourceID": "WEB",
            "X-ClientLocalIP": "192.168.168.168",
            "X-ClientPublicIP": "106.193.147.98",
            "X-MACAddress": "fe80::216e:6507:4b90:3719",
            "X-PrivateKey": api_key,
          },
        }
      );

      if (response.ok) {
        const fundData = await response.json();
        if (fundData.data.availablecash !== undefined) {
          return `Rs. ${parseFloat(fundData.data.availablecash).toFixed(2)}`;
        } else {
          return "Not Available";
        }
      } else {
        console.error("Failed to fetch funds:", response.statusText);
        return "Failed to fetch funds";
      }
    } catch (error) {
      console.error("Error fetching funds:", error);
      return "Error fetching funds";
    }
  };

  const upstoxFunds = async (jwt_token) => {
    try {
      const response = await fetch(
        "https://api.upstox.com/v2/user/get-funds-and-margin",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwt_token}`,
            Accept: "application/json",
          },
        }
      );

      console.log("Response status:", response.status);
      if (response.ok) {
        const fundData = await response.json();
        console.log("Upstox funds data:", fundData);
        const equityFunds = fundData.data.equity.available_margin;
        return `Rs. ${parseFloat(equityFunds).toFixed(2)}`;
      } else {
        console.error("Failed to fetch Upstox funds:", response.statusText);
        return "Not Available";
      }
    } catch (error) {
      console.error("Error fetching Upstox funds:", error);
      return "Error fetching Upstox funds";
    }
  };

  const handleCustomerDeactive = async (customer_id) => {
    if (window.confirm("Are you sure you want to deactivate this customer?")) {
      try {
        const response = await fetch(CUSTOMER_DEACTIVE, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ customer_id }),
        });

        if (response.ok) {
          toast.success("Successfully Deactivated");
          fetchAllCustomers();
        } else {
          toast.error("Failed to log out. Please try again.");
        }
      } catch (error) {
        toast.error("An error occurred. Please try again.");
      }
    } else {
      toast.info("Deactivation cancelled");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="my-3">
        <h4 className="mb-3 text-center">All Customers</h4>
        <div className="d-flex mb-3">
          <input
            type="text"
            className="form-control mr-2"
            placeholder="Search by Name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <select
            className="form-control mr-2"
            value={sortPlatform}
            onChange={(e) => setSortPlatform(e.target.value)}
          >
            <option value="">All Platforms</option>
            <option value="AngelOne">AngelOne</option>
            <option value="Upstox">Upstox</option>
          </select>
          <select
            className="form-control mr-2"
            value={sortPlan}
            onChange={(e) => setSortPlan(e.target.value)}
          >
            <option value="">All Plans</option>
            <option value="NOPLAN">No Plan</option>
            <option value="demo">Demo</option>
            <option value="1month">1 Month</option>
            <option value="3month">3 Months</option>
            <option value="6month">6 Months</option>
            <option value="12month">12 Months</option>
          </select>
          <select
            className="form-control"
            value={sortStatus}
            onChange={(e) => setSortStatus(e.target.value)}
          >
            <option value="">Status</option>
            <option value="ACTIVE">Active</option>
            <option value="DEACTIVE">Deactive</option>
          </select>
        </div>
        {customersList.length > 0 ? (
          <div className="customerTable">
            <table className="table table-striped text-center">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Platform Type</th>
                  {/* <th>Email</th> */}
                  <th>Mobile</th>
                  {/* <th>Funds</th> */}
                  <th>Plan</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {/* {customersList */}
                {filteredCustomers
                  .sort((a, b) => (a.customer_status === "ACTIVE" ? -1 : 1))
                  .map((customer) => (
                    <tr key={customer.customer_id}>
                      <td>{customer.customer_name}</td>
                      {/* <td>{customer.customer_platformType}</td> */}
                      <td>
                        <div className="platform-logo-container">
                          {customer.customer_platformType === "AngelOne" ? (
                            <img
                              className="text-center my-1 platform-logo"
                              src="https://cdn.angelone.in/sparkweb/images/angel-one-logo.png"
                              alt={customer.customer_platformType}
                            />
                          ) : customer.customer_platformType === "Upstox" ? (
                            <img
                              className="text-center my-1 platform-logo"
                              src="https://upstox.com/app/themes/upstox/dist/img/logo/desktop-logo.svg"
                              alt={customer.customer_platformType}
                            />
                          ) : (
                            customer.customer_platformType
                          )}
                        </div>
                      </td>
                      {/* <td>{customer.customer_email}</td> */}
                      <td>{customer.mobile}</td>
                      {/* <td>
                      {fundsData[customer.customer_id] !== undefined
                        ? fundsData[customer.customer_id]
                        : "Loading..."}
                    </td> */}
                      <td>
                        {customer.plan === "NOPLAN"
                          ? "No Plan"
                          : customer.plan === "demo"
                          ? "Demo"
                          : customer.plan === "1month"
                          ? "1 Month"
                          : customer.plan === "3month"
                          ? "3 Months"
                          : customer.plan === "6month"
                          ? "6 Months"
                          : customer.plan === "12month"
                          ? "12 Months"
                          : ""}
                      </td>
                      <td>
                        <button
                          className={
                            customer.customer_status === "ACTIVE"
                              ? "btn btn-success w-100"
                              : "btn btn-danger w-100"
                          }
                          onClick={() => {
                            if (customer.customer_status === "ACTIVE") {
                              handleCustomerDeactive(customer.customer_id);
                            }
                          }}
                        >
                          {customer.customer_status}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No customers found</p>
        )}
      </div>
    </>
  );
}
