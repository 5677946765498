import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  CREATE_GROUP,
  ADD_CUSTOMER_TO_GROUP,
  SHOW_GROUP_WITH_CUSTOMER,
  REMOVE_CUSTOMER_FROM_GROUP,
  DELETE_GROUP,
  ALL_CUSTOMER_EMPLOYEE,
  EDIT_LOT_GROUP,
} from "../../Config/api";

export default function Groups() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    group_name: "",
    description: "",
    option: "",
  });
  const [groups, setGroups] = useState([]);
  // const [expandedGroup, setExpandedGroup] = useState(null);
  // const [showModal, setShowModal] = useState(false);
  const [customersForGroup, setCustomersForGroup] = useState([]);
  const [groupNameView, setGroupNameView] = useState("");
  const [customersList, setCustomersList] = useState([]);
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
  const [currentGroupName, setCurrentGroupName] = useState("");
  const [lotSize, setLotSize] = useState("1");
  const [groupOptionName, setgroupOptionName] = useState("");
  const [lotSizes, setLotSizes] = useState({});
  const [customerIndexLots, setCustomerIndexLots] = useState({});
  const handleLotSizeChange = (customerId, value) => {
    setLotSizes((prevLotSizes) => ({
      ...prevLotSizes,
      [customerId]: value,
    }));
  };
  const [indexLots, setIndexLots] = useState({
    BANKNIFTY: "",
    NIFTY50: "",
    FINNIFTY: "",
    MIDCPNIFTY: "",
    SENSEX: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await fetch(CREATE_GROUP, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Group created successfully!");
        setFormData({
          group_name: "",
          description: "",
          option: "",
        });
        fetchGroups();
      } else {
        const data = await response.json();
        throw new Error(data.message);
      }
    } catch (error) {
      console.error("Error creating group:", error.message);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchGroups = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await fetch(SHOW_GROUP_WITH_CUSTOMER, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setGroups(data || []);
      } else {
        throw new Error("Failed to fetch groups");
      }
    } catch (error) {
      console.error("Error fetching groups:", error.message);
      toast.error("Failed to fetch groups. Please try again.");
    }
  };

  useEffect(() => {
    fetchGroups();
    fetchAllCustomers();
  }, []);

  const [viewCustomerOption, setViewCustomerOption] = useState("");

  const customerInGroup = async (groupName, groupOption) => {
    setViewCustomerOption(groupOption);
    setGroupNameView(groupName);
    try {
      // setExpandedGroup(groupName);
      const accessToken = localStorage.getItem("accessToken");
      const response = await fetch(
        `${SHOW_GROUP_WITH_CUSTOMER}?group_name=${groupName}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const customersForGroup = data.filter(
          (item) => item.group_name === groupName
        );

        setCustomersForGroup(customersForGroup);
        // setShowModal(true);
      } else {
        throw new Error("Failed to fetch customers for the group");
      }
    } catch (error) {
      console.error("Error fetching customers for group:", error.message);
      toast.error("Failed to fetch customers for the group. Please try again.");
    }
  };

  // const closeModal = () => {
  //   setShowModal(false);
  //   setShowAddCustomerModal(false);
  // };

  const removeCustomer = async (
    customerId,
    groupName,
    customerPlatform,
    groupOption
  ) => {
    setLoading(true);
    const removeData = {
      customer_id: customerId,
      group_name: groupName,
      customer_platformType: customerPlatform,
    };

    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await fetch(REMOVE_CUSTOMER_FROM_GROUP, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(removeData),
      });

      if (response.ok) {
        toast.success("Customer Removed");
        setFormData({
          group_name: "",
          description: "",
        });
        customerInGroup(groupName, groupOption);
      } else {
        const data = await response.json();
        throw new Error(data.message);
      }
    } catch (error) {
      console.error("Error removing Customer:", error.message);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteGroup = async (groupName) => {
    setLoading(true);
    const removeData = {
      group_name: groupName,
    };

    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await fetch(DELETE_GROUP, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(removeData),
      });

      if (response.ok) {
        toast.success("Group Deleted");
        setFormData({
          group_name: "",
          description: "",
        });
        fetchGroups();
      } else {
        const data = await response.json();
        throw new Error(data.message);
      }
    } catch (error) {
      console.error("Error deleting Group:", error.message);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllCustomers = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await fetch(ALL_CUSTOMER_EMPLOYEE, requestOptions);
      if (response.ok) {
        const data = await response.json();
        setCustomersList(data.customers);
      } else {
        toast.error("Failed to fetch customers");
        console.error("Failed to fetch customers:", response.statusText);
      }
    } catch (error) {
      toast.error("Error fetching customers");
      console.error("Error fetching customers:", error);
    }
  };
  const [notInGroup, setNotInGroup] = useState([]);

  const handleAddCustomerClick = async (groupName, groupOption) => {
    console.log(groupName, groupOption);
    setgroupOptionName(groupOption);
    setCurrentGroupName(groupName);
    await customerInGroup(groupName, groupOption);
  };

  useEffect(() => {
    if (currentGroupName) {
      const customerUidsInGroup = customersForGroup
        .filter((group) => group.group_name === currentGroupName)
        .map((group) => {
          const uid = group.customer_uid;
          return `${uid.substring(0, 8)}-${uid.substring(
            8,
            12
          )}-${uid.substring(12, 16)}-${uid.substring(16, 20)}-${uid.substring(
            20
          )}`;
        });

      const customersNotInGroup = customersList.filter(
        (customer) => !customerUidsInGroup.includes(customer.customer_id)
      );

      setNotInGroup(customersNotInGroup);
      setShowAddCustomerModal(true);
    }
  }, [customersForGroup, currentGroupName, customersList]);

  // const addCustomerToGroup = async (customerId, platformType) => {
  //   setLoading(true);
  //   const cleanedCustomerId = customerId.replace(/-/g, "");
  //   const addData = {
  //     customer_id: cleanedCustomerId,
  //     group_name: currentGroupName,
  //     customer_platformType: platformType,
  //     lot_size: groupOptionName === "index" ? indexLots : lotSize,
  //     ...(groupOptionName === "stock"
  //       ? { lot_size: lotSize }
  //       : {
  //           BANKNIFTY: indexLots.BANKNIFTY,
  //           NIFTY50: indexLots.NIFTY50,
  //           FINNIFTY: indexLots.FINNIFTY,
  //           MIDCPNIFTY: indexLots.MIDCPNIFTY,
  //           SENSEX: indexLots.SENSEX,
  //         }),
  //   };

  //   if (addData.lot_size === "0" || addData.lot_size === "") {
  //     return toast.error("Lot Size cannot be Zero");
  //   } else {
  //     // Handle the submission logic here
  //     console.log(addData);
  //   }
  //   if (
  //     groupOptionName === "index" &&
  //     !Object.values(indexLots).some((value) => value !== "")
  //   ) {
  //     toast.error("Please fill at least one index lot size");
  //     setLoading(false);
  //     return;
  //   }

  //   try {
  //     const accessToken = localStorage.getItem("accessToken");
  //     if (!accessToken) {
  //       throw new Error("Access token not found");
  //     }

  //     const response = await fetch(ADD_CUSTOMER_TO_GROUP, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //       body: JSON.stringify(addData),
  //     });

  //     if (response.ok) {
  //       toast.success("Customer Added");
  //       setLotSize("");
  //       setIndexLots({
  //         BANKNIFTY: "",
  //         NIFTY50: "",
  //         FINNIFTY: "",
  //         MIDCPNIFTY: "",
  //         SENSEX: "",
  //       });
  //       fetchGroups();
  //       handleAddCustomerClick(currentGroupName, groupOptionName);
  //     } else {
  //       const data = await response.json();
  //       throw new Error(data.message || "Failed to add customer");
  //     }
  //   } catch (error) {
  //     console.error("Error adding Customer:", error.message);
  //     toast.error(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const addCustomerToGroup = async (customerId, platformType) => {
    setLoading(true);
    const cleanedCustomerId = customerId.replace(/-/g, "");
    const lotSize = lotSizes[customerId] || ""; // Get the lot size for the specific customer
    const addData = {
      customer_id: cleanedCustomerId,
      group_name: currentGroupName,
      customer_platformType: platformType,
      lot_size: groupOptionName === "index" ? indexLots : lotSize,
      ...(groupOptionName === "stock"
        ? { lot_size: lotSize }
        : {
            BANKNIFTY: indexLots.BANKNIFTY,
            NIFTY50: indexLots.NIFTY50,
            FINNIFTY: indexLots.FINNIFTY,
            MIDCPNIFTY: indexLots.MIDCPNIFTY,
            SENSEX: indexLots.SENSEX,
          }),
    };

    if (addData.lot_size === '0' || addData.lot_size === '') {
      setLoading(false);
      return toast.error("Lot Size cannot be Zero");
    } else {
      // Handle the submission logic here
      console.log(addData);
    }
    if (
      groupOptionName === "index" &&
      !Object.values(indexLots).some((value) => value !== "")
    ) {
      toast.error("Please fill at least one index lot size");
      setLoading(false);
      return;
    }

    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        throw new Error("Access token not found");
      }

      const response = await fetch(ADD_CUSTOMER_TO_GROUP, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(addData),
      });

      if (response.ok) {
        toast.success("Customer Added");
        setLotSizes((prevLotSizes) => ({
          ...prevLotSizes,
          [customerId]: "", // Clear the lot size for the added customer
        }));
        fetchGroups();
        handleAddCustomerClick(currentGroupName, groupOptionName);
      } else {
        const data = await response.json();
        throw new Error(data.message || "Failed to add customer");
      }
    } catch (error) {
      console.error("Error adding Customer:", error.message);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleIndexLotsChange = (e) => {
    const { name, value } = e.target;
    setIndexLots({
      ...indexLots,
      [name]: value,
    });
  };

  // edit lot

  const [isEditLot, setIsEditLot] = useState(false);

  // const editGroupLots = (customerId, groupName, platformType) => {
  //   setIsEditLot((prevEditStates) => ({
  //     ...prevEditStates,
  //     [customerId]: true,
  //   }));

  //   console.log(customerId, groupName, platformType)
  // };

  const editGroupLots = (customerId) => {
    setIsEditLot((prevEditStates) => ({
      ...prevEditStates,
      [customerId]: true,
    }));
  };

  const saveEditedGroupLots = (customerId, groupName, platformType) => {
    setIsEditLot((prevEditStates) => ({
      ...prevEditStates,
      [customerId]: false,
    }));

    // here
  };

  return (
    <div className="content-wrapper">
      <form onSubmit={handleSubmit}>
        <h4 className="mb-3 text-center">Create New Group</h4>
        <div className="row">
          <div className="form-group my-2 col-md-3">
            <input
              type="text"
              name="group_name"
              placeholder="Group Name"
              value={formData.group_name}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          <div className="form-group my-2 col-md-3">
            <input
              type="text"
              name="description"
              placeholder="Group Description"
              value={formData.description}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          <div className="form-group my-2 col-md-3">
            <select
              className="form-control"
              value={formData.option}
              onChange={handleChange}
              name="option"
              required
            >
              <option value="" disabled>
                Select Options
              </option>
              <option value="index">Index Option</option>
              <option value="stock">Stock Option</option>
            </select>
          </div>
          <div className="form-group my-2 col-md-3">
            <button
              type="submit"
              className="btn btn-primary groupBtn"
              disabled={loading}
            >
              {loading ? "Creating Group..." : "Create Group"}
            </button>
          </div>
        </div>
      </form>
      <div>
        <h4 className="text-center my-3">Groups</h4>
        <table className="table table-striped text-center">
          <thead>
            <tr>
              <th>Group Name</th>
              <th>Description</th>
              <th>Customers</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {groups && groups.length > 0 ? (
              groups.map((group) => (
                <tr key={group.group_name}>
                  <td>{group.group_name}</td>
                  <td>{group.description}</td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-toggle="modal"
                      data-target="#exampleModal"
                      onClick={() =>
                        customerInGroup(group.group_name, group.option)
                      }
                    >
                      {/* View Customers */}
                      <i className="zmdi zmdi-eye"></i>
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger px-3 py-2 removeBtnCustomer"
                      onClick={() => deleteGroup(group.group_name)}
                    >
                      <i className="zmdi zmdi-delete"></i>
                    </button>
                    <button
                      className="btn btn-secondary ml-2"
                      data-target="#addCustomerModal"
                      data-toggle="modal"
                      onClick={() =>
                        handleAddCustomerClick(group.group_name, group.option)
                      }
                    >
                      {/* Add Customer */}
                      <i className="fa fa-plus"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center">
                  No Groups Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <ToastContainer />

      {/* View Customers Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Customers List
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {viewCustomerOption === "index" ? (
                customersForGroup.length > 0 ? (
                  <table className="table text-center">
                    <thead>
                      <tr>
                        <th>Customer Name</th>
                        <th>Mobile</th>
                        <th>Platform</th>
                        <th>BANKNIFTY</th>
                        <th>NIFTY50</th>
                        <th>FINNIFTY</th>
                        <th>MIDCPNIFTY</th>
                        <th>SENSEX</th>
                        {/* <th>Status</th> */}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customersForGroup.map((customer, index) => (
                        <tr key={index}>
                          <td>{customer.customer_name}</td>
                          <td>{customer.mobile}</td>
                          <td>{customer.customer_platformType}</td>
                          <td>
                            {!isEditLot[customer.customer_uid] ? (
                              customer.BANKNIFTY
                            ) : (
                              <input
                                className="form-control"
                                value={customer.BANKNIFTY}
                              />
                            )}
                          </td>
                          <td>
                            {!isEditLot[customer.customer_uid] ? (
                              customer.NIFTY50
                            ) : (
                              <input
                                className="form-control"
                                value={customer.NIFTY50}
                              />
                            )}
                          </td>
                          <td>
                            {!isEditLot[customer.customer_uid] ? (
                              customer.FINNIFTY
                            ) : (
                              <input
                                className="form-control"
                                value={customer.FINNIFTY}
                              />
                            )}
                          </td>
                          <td>
                            {!isEditLot[customer.customer_uid] ? (
                              customer.MIDCPNIFTY
                            ) : (
                              <input
                                className="form-control"
                                value={customer.MIDCPNIFTY}
                              />
                            )}
                          </td>
                          <td>
                            {!isEditLot[customer.customer_uid] ? (
                              customer.SENSEX
                            ) : (
                              <input
                                className="form-control"
                                value={customer.SENSEX}
                              />
                            )}
                          </td>
                          {/* <td>
                            <button
                              className={
                                customer.customer_status == "ACTIVE"
                                  ? "btn btn-success w-100"
                                  : "btn btn-danger w-100"
                              }
                            >
                              {customer.customer_status}
                            </button>
                          </td> */}
                          <td>
                            {/* {isEditLot[customer.customer_uid] ? (
                              <button
                                className="btn btn-success mx-1"
                                onClick={() =>
                                  saveEditedGroupLots(
                                    customer.customer_uid,
                                    customer.group_name,
                                    customer.customer_platformType
                                  )
                                }
                              >
                                <i className="zmdi zmdi-check"></i>
                              </button>
                            ) : (
                              <button
                                className="btn btn-warning mx-1"
                                onClick={() =>
                                  editGroupLots(
                                    customer.customer_uid,
                                    customer.group_name,
                                    customer.customer_platformType
                                  )
                                }
                              >
                                <i className="zmdi zmdi-edit"></i>
                              </button>
                            )} */}

                            <button
                              className="btn btn-danger px-3 mx-1 py-2 removeBtnCustomer"
                              onClick={() =>
                                removeCustomer(
                                  customer.customer_uid,
                                  groupNameView,
                                  customer.customer_platformType,
                                  customer.option
                                )
                              }
                            >
                              <i className="zmdi zmdi-delete"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p className="text-center">No Customers</p>
                )
              ) : customersForGroup.length > 0 ? (
                <table className="table text-center">
                  <thead>
                    <tr>
                      <th>Customer Name</th>
                      <th>Mobile</th>
                      <th>Platform</th>
                      <th>Lot Size</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customersForGroup.map((customer, index) => (
                      <tr key={index}>
                        <td>{customer.customer_name}</td>
                        <td>{customer.mobile}</td>
                        <td>{customer.customer_platformType}</td>
                        <td>
                          {!isEditLot[customer.customer_uid] ? (
                            customer.lot_size
                          ) : (
                            <input
                              className="form-control"
                              value={customer.lot_size}
                            />
                          )}
                        </td>
                        <td>{customer.customer_status}</td>
                        <td>
                          {/* {isEditLot[customer.customer_uid] ? (
                            <button
                              className="btn btn-success mx-1"
                              onClick={() =>
                                saveEditedGroupLots(
                                  customer.customer_uid,
                                  customer.group_name,
                                  customer.customer_platformType
                                )
                              }
                            >
                              <i className="zmdi zmdi-check"></i>
                            </button>
                          ) : (
                            <button
                              className="btn btn-warning mx-1"
                              onClick={() =>
                                editGroupLots(
                                  customer.customer_uid,
                                  customer.group_name,
                                  customer.customer_platformType
                                )
                              }
                            >
                              <i className="zmdi zmdi-edit"></i>
                            </button>
                          )} */}
                          <button
                            className="btn btn-danger px-3 py-2 removeBtnCustomer"
                            onClick={() =>
                              removeCustomer(
                                customer.customer_uid,
                                groupNameView,
                                customer.customer_platformType,
                                customer.option
                              )
                            }
                          >
                            <i className="zmdi zmdi-delete"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="text-center">No Customers</p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Add Customer Modal */}
      {showAddCustomerModal && (
        <div
          className="modal fade"
          id="addCustomerModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="addCustomerModal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="addCustomerModal">
                  Add Customer to {currentGroupName}
                </h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  data-dismiss="modal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {groupOptionName === "stock" ? (
                  notInGroup.length > 0 ? (
                    <table className="table text-center">
                      <thead>
                        <tr>
                          <th>Customer Name</th>
                          <th>Lot Size</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {notInGroup.map((customer, index) => (
                          <tr key={index}>
                            <td>{customer.customer_name}</td>
                            <td>
                              <input
                                className="form-control "
                                placeholder="Enter Lot Size"
                                type="number"
                                min="1"
                                // onChange={(e) => setLotSize(e.target.value)}
                                value={lotSizes[customer.customer_id] || ""}
                                onChange={(e) =>
                                  handleLotSizeChange(
                                    customer.customer_id,
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <button
                                className="btn btn-primary px-3 py-2"
                                onClick={() =>
                                  addCustomerToGroup(
                                    customer.customer_id,
                                    customer.customer_platformType
                                  )
                                }
                              >
                                Add
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p className="text-center">No Customers Available</p>
                  )
                ) : notInGroup.length > 0 ? (
                  <table className="table text-center">
                    <thead>
                      <tr>
                        <th>Customer Name</th>
                        <th>BANKNIFTY</th>
                        <th>NIFTY50</th>
                        <th>FINNIFTY</th>
                        <th>MIDCPNIFTY</th>
                        <th>SENSEX</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {notInGroup.map((customer, index) => (
                        <tr key={index}>
                          <td>{customer.customer_name}</td>
                          <td>
                            <input
                              className="form-control "
                              placeholder="Enter BANKNIFTY"
                              type="number"
                              min="1"
                              name="BANKNIFTY"
                              onChange={handleIndexLotsChange}
                            />
                          </td>
                          <td>
                            <input
                              className="form-control "
                              placeholder="Enter NIFTY50"
                              type="number"
                              min="1"
                              name="NIFTY50"
                              onChange={handleIndexLotsChange}
                            />
                          </td>
                          <td>
                            <input
                              className="form-control "
                              placeholder="Enter FINNIFTY"
                              type="number"
                              min="1"
                              name="FINNIFTY"
                              onChange={handleIndexLotsChange}
                            />
                          </td>
                          <td>
                            <input
                              className="form-control "
                              placeholder="Enter MIDCPNIFTY"
                              type="number"
                              min="1"
                              name="MIDCPNIFTY"
                              onChange={handleIndexLotsChange}
                            />
                          </td>
                          <td>
                            <input
                              className="form-control "
                              placeholder="Enter SENSEX"
                              type="number"
                              min="1"
                              name="SENSEX"
                              onChange={handleIndexLotsChange}
                            />
                          </td>
                          <td>
                            <button
                              className="btn btn-primary px-3 py-2"
                              onClick={() =>
                                addCustomerToGroup(
                                  customer.customer_id,
                                  customer.customer_platformType
                                )
                              }
                            >
                              Add
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p className="text-center">No Customers Available</p>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  id="closeModal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
