import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chart from "react-apexcharts";
import { SHOW_NUMBER_SUPERAADMIN } from "../Config/api";

const Dashboard = () => {
  const [data, setData] = useState({
    total_companies: 0,
    total_employees: 0,
    total_customers: 0,
    total_order_history: 0,
    total_deactive_customers: 0,
    total_active_customers: 0,
    total_noplan_customers: 0,
    total_1month_customers: 0,
    total_3month_customers: 0,
    total_6month_customers: 0,
    total_12month_customers: 0,
  });

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const fetchData = async () => {
      try {
        const response = await fetch(SHOW_NUMBER_SUPERAADMIN, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`, // replace accessToken with the actual token variable
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        toast.error("Failed to fetch data");
      }
    };

    fetchData();
  }, []);

  const pieChartOptions = {
    labels: ["No Plan", "1 Month", "3 Month", "6 Month", "12 Month"],
  };

  const pieChartSeries = [
    data.total_noplan_customers,
    data.total_1month_customers,
    data.total_3month_customers,
    data.total_6month_customers,
    data.total_12month_customers,
  ];

  const donutChartOptions = {
    labels: ["Active Customers", "Deactive Customers"],
    colors: ["#439046", "#9f1926"], // Green for active, Red for deactive
  };

  const donutChartSeries = [
    data.total_active_customers,
    data.total_deactive_customers,
  ];

  const lineChartOptions = {
    chart: {
      id: "line-chart",
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: ["Total Customers", "Total Trades"],
      title: {
        text: "Metrics",
      },
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
    markers: {
      size: 4,
      colors: ["#FFA41B"],
    },
    dataLabels: {
      enabled: true,
    },
    title: {
      text: "Total Trades and Total Customers",
      align: "center",
    },
  };

  const totalCustomers = data.total_customers;
  const totalTrades = data.total_order_history;
  const averageTrades = totalCustomers
    ? Math.round(totalTrades / totalCustomers)
    : 0;

  const lineChartSeries = [
    {
      name: "Total Customers",
      data: [totalCustomers],
    },
    {
      name: "Total Trades",
      data: [totalTrades],
    },
    {
      name: "Average Trades per Customer",
      data: [averageTrades],
    },
  ];

  return (
    <div className="content-wrapper">
      <div className="container-fluid">
        <div className="card mt-3">
          <div className="card-content">
            <div className="row row-group m-0 dashboard-tiles-container">
              <div className="col-12 col-lg-4 col-xl-3 border-light">
                <div className="card-body">
                  <h3 className="text-white mb-0 price-stock">
                    {data.total_companies}
                  </h3>
                  <h5 className="mb-0 text-white small-font">
                    Total Companies
                  </h5>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-xl-3 border-light">
                <div className="card-body">
                  <h3 className="text-white mb-0 price-stock">
                    {data.total_customers}
                  </h3>
                  <h5 className="mb-0 text-white small-font">
                    Total Customers
                  </h5>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-xl-3 border-light">
                <div className="card-body">
                  <h3 className="text-white mb-0 price-stock">
                    {data.total_order_history}
                  </h3>
                  <h5 className="mb-0 text-white small-font">Total Trades</h5>
                </div>
              </div>

              <div className="col-12 col-lg-4 col-xl-3 border-light">
                <div className="card-body">
                  <h3 className="text-white mb-0 price-stock">
                    {data.total_employees}
                  </h3>
                  <h5 className="mb-0 text-white small-font">
                    Total Employees
                  </h5>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-xl-3 border-light">
                <div className="card-body">
                  <h3 className="text-white mb-0 price-stock">
                    {data.total_active_customers}
                  </h3>
                  <h5 className="mb-0 text-white small-font">
                    Active Customers
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row my-3 graphs-container">
            <div className="col-12 col-lg-6">
              <h4 className="text-center">Customer Plans</h4>
              <div className="card mt-3">
                <div className="card-content">
                  <Chart
                    options={pieChartOptions}
                    series={pieChartSeries}
                    type="pie"
                    height="350"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <h4 className="text-center">Customer Status</h4>
              <div className="card mt-3">
                <div className="card-content">
                  <Chart
                    options={donutChartOptions}
                    series={donutChartSeries}
                    type="donut"
                    height="350"
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-12">
              <h4 className="text-center">Total Trades and Customers</h4>
              <div className="metrics-card">
                <p>Average Trades per Customer: {averageTrades}</p>
              </div>
              <div className="card mt-3">
                <div className="card-content">
                  <Chart
                    options={lineChartOptions}
                    series={lineChartSeries}
                    type="line"
                    height="350"
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Dashboard;
