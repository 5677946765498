import React, { useState, createContext, useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Navbar from "./Components/Navbar";
import Dashboard from "./Pages/Dashboard";
import Sidebar from "./Components/Sidebar";
import Login from "./Pages/Login";
import Profile from "./Pages/Profile";
import CompanyHistory from "./Pages/CompanyHistory";
import "./App.css";
import CreateCompany from "./Pages/CreateCompany";

// Company Routes by Arsalan
import CompanyDashboard from "./Pages/CompanyData/CompanyDashboard";
import CompanyEmployee from "./Pages/CompanyData/CompanyEmployee";
import Customer from "./Pages/CompanyData/Customer";
import CompanyProfile from "./Pages/CompanyData/CompanyProfile";

// Employee Routes by Arsalan
import EmployeeDashboard from "./Pages/EmployeeData/EmployeeDashboard";
import Graph from "./Pages/Graph";
import CandleGraph from "./Pages/EmployeeData/CandleGraph";
import Options from "./Pages/EmployeeData/Options";
import Groups from "./Pages/EmployeeData/Groups";
import EmployeeCustomer from "./Pages/EmployeeData/EmployeeCustomer";
import OrderHistory from "./Pages/EmployeeData/OrderHistory";
import OrderReports from "./Pages/CompanyData/OrderReports";
import EmployeeProfile from "./Pages/EmployeeData/EmployeeProfile";


// Subadmin
import DashboardSubAdmin from "./Pages/SubAdminData/DashboardSubAdmin";
import SubAdminEmployee from "./Pages/CompanyData/SubAdminEmployee";
import SubAdminCoadmin from "./Pages/SubAdminData/SubAdminCoadmin";
import SubAdminProfile from "./Pages/SubAdminData/SubAdminProfile";
import AddCustomerSubadmin from "./Pages/SubAdminData/AddCustomerSubadmin";
import LeadGen from "./Pages/SubAdminData/LeadGen";

// Create a context for permissions
const PermissionsContext = createContext();

// Custom hook to use permissions context
export const usePermissions = () => useContext(PermissionsContext);

const logout = (navigate) => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("role");
  navigate("/login", { replace: true });
};

const ProtectedRoute = ({ children, role, allowedRoles }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!role) {
      console.log("No role found. Redirecting to login.");
      logout(navigate);
    } else if (!allowedRoles.includes(role)) {
      console.log("Unauthorized access attempt. Redirecting to login.");
      logout(navigate);
    }
  }, [role, allowedRoles, navigate]);

  return allowedRoles.includes(role) ? children : null;
};

function App() {
  const [isToggled, setIsToggled] = useState(false);
  const [role, setRole] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // Function to toggle the sidebar menu
  function toggleSidebarMenu() {
    setIsToggled(!isToggled);
  }

  useEffect(() => {
    const storedRole = localStorage.getItem("role");
    if (storedRole) {
      setRole(storedRole);
    }
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <PermissionsContext.Provider value={{ role }}>
      <Router>
        <div id="wrapper" className={isToggled ? "toggled" : ""}>
          <Routes>
            <Route path="/login" element={<Login />} />

            {/* Superadmin */}
            <Route
              path="/*"
              element={
                <ProtectedRoute role={role} allowedRoles={["superadmin"]}>
                  <>
                    <Navbar toggleSidebar={toggleSidebarMenu} />
                    <Sidebar isToggled={isToggled} />
                    <Routes>
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/company" element={<CreateCompany />} />
                      <Route
                        path="/company-history/:id"
                        element={<CompanyHistory />}
                      />
                      {/* Add other superadmin routes here */}
                    </Routes>
                  </>
                </ProtectedRoute>
              }
            />

            {/* Company */}
            <Route
              path="/company-data/*"
              element={
                <ProtectedRoute role={role} allowedRoles={["company"]}>
                  <>
                    <Navbar toggleSidebar={toggleSidebarMenu} />
                    <Sidebar isToggled={isToggled} />
                    <Routes>
                      <Route path="/" element={<CompanyDashboard />} />
                      <Route path="/profile" element={<CompanyProfile />} />
                      <Route
                        path="/company-employee"
                        element={<CompanyEmployee />}
                      />
                      <Route path="/customer" element={<Customer />} />
                      <Route
                        path="/customer-reports"
                        element={<OrderReports />}
                      />

                      <Route path="/subadmin" element={<SubAdminEmployee />} />
                    </Routes>
                  </>
                </ProtectedRoute>
              }
            />

            {/* Employee */}
            <Route
              path="/employee-data/*"
              element={
                <ProtectedRoute role={role} allowedRoles={["employee"]}>
                  <>
                    <Navbar toggleSidebar={toggleSidebarMenu} />
                    <Sidebar isToggled={isToggled} />
                    <Routes>
                      <Route path="/" element={<EmployeeDashboard />} />
                      <Route path="/groups" element={<Groups />} />
                      <Route path="/customer" element={<EmployeeCustomer />} />
                      <Route path="/graph/:id" element={<Graph />} />
                      <Route path="/options/:name" element={<Options />} />
                      <Route
                        path="/customer-reports"
                        element={<OrderHistory />}
                      />
                      <Route
                        path="/candlegraph/:id/:exch_seg"
                        element={<CandleGraph />}
                      />
                      <Route path="/profile" element={<EmployeeProfile />} />
                    </Routes>
                  </>
                </ProtectedRoute>
              }
            />
            {/* Subadmin */}
            <Route
              path="/subadmin/*"
              element={
                <ProtectedRoute role={role} allowedRoles={["subadmin"]}>
                  <>
                    <Navbar toggleSidebar={toggleSidebarMenu} />
                    <Sidebar isToggled={isToggled} />
                    <Routes>
                      <Route path="/" element={<DashboardSubAdmin />} />
                      <Route path="/subadmin" element={<SubAdminCoadmin />} />
                      <Route path="/profile" element={<SubAdminProfile />} />
                      <Route path="/add-customer" element={<AddCustomerSubadmin />} />
                      <Route path="/share-link" element={<LeadGen />} />
                      {/* Add other superadmin routes here */}
                    </Routes>
                  </>
                </ProtectedRoute>
              }
            />

            {/* Fallback for unauthorized access */}
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </div>
      </Router>
    </PermissionsContext.Provider>
  );
}

export default App;
