import React, { useState, useEffect } from "react";
import {
  CREATE_COMPANY,
  SHOW_ALL_COMPANY,
  ACTIVE_DEACTIVE_COMPANY,
} from "../Config/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { enforceMaxLength } from "../Config/utility";

export default function CreateCompany() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    owner_name: "",
    company_name: "",
    mobile: "",
    user_name: "",
    super_admin: "1",
    logo: null, // Add logo to formData
  });

  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    fetchAllCompanies();
  }, []);

  const fetchAllCompanies = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await fetch(SHOW_ALL_COMPANY, requestOptions);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setCompanies(data);
        console.log(data[0].employees);
      } else {
        console.error("Failed to fetch companies:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setFormData({
        ...formData,
        logo: file,
      });
    } else {
      toast.error("Please select a valid image file");
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   const accessToken = localStorage.getItem("accessToken");
  //   const formDataToSend = new FormData();
  //   for (const key in formData) {
  //     formDataToSend.append(key, formData[key]);
  //   }

  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //     body: formDataToSend,
  //   };

  //   try {
  //     const response = await fetch(CREATE_COMPANY, requestOptions);
  //     const data = await response.json();
  //     if (response.ok) {
  //       console.log("Company created successfully!");
  //       toast("Company created successfully!");
  //       fetchAllCompanies();
  //       setFormData({
  //         email: "",
  //         password: "",
  //         owner_name: "",
  //         company_name: "",
  //         mobile: "",
  //         user_name: "",
  //         super_admin: "1",
  //         logo: null,
  //       });
  //     } else {
  //       console.error("Failed to create company:", response.statusText);
  //       if (data.email) {
  //         toast.error(data.email[0].toUpperCase());
  //       }
  //       if (data.user_name) {
  //         toast.error(data.user_name[0].toUpperCase());
  //       }
  //       if (data.mobile) {
  //         toast.error(data.mobile[0].toUpperCase());
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (formData.mobile.length !== 10) {
      toast.warning("Please Enter 10 digits Mobile Number");
      setLoading(false);
      return; // Return early if validation fails
    }

    const accessToken = localStorage.getItem("accessToken");
    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formDataToSend,
    };

    try {
      const response = await fetch(CREATE_COMPANY, requestOptions);
      const data = await response.json();

      if (response.ok) {
        console.log("Company created successfully!");
        toast.success("Company created successfully!");
        fetchAllCompanies();
        setFormData({
          email: "",
          password: "",
          owner_name: "",
          company_name: "",
          mobile: "",
          user_name: "",
          super_admin: "1",
          logo: null,
        });
      } else {
        console.error("Failed to create company:", response.statusText);
        if (data.email) {
          toast.error(data.email[0].toUpperCase());
        }
        if (data.user_name) {
          toast.error(data.user_name[0].toUpperCase());
        }
        if (data.mobile) {
          toast.error(data.mobile[0].toUpperCase());
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while creating the company.");
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  const checkCompany = (company) => {
    navigate(`/company-history/${company}`);
  };

  const handleStatusChange = async (company, status) => {
    const newStatus = status === 0 ? 1 : 0;
    const url = ACTIVE_DEACTIVE_COMPANY.replace(':id', company);
    const accessToken = localStorage.getItem('accessToken'); // Retrieve access token from local storage
  
    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`, // Add Authorization header
        },
        body: JSON.stringify({ active: newStatus }),
      });
  
      if (!response.ok) {
        const errorText = await response.text(); // Capture the response body for debugging
        throw new Error(`Network response was not ok: ${errorText}`);
      }
  
      // Optionally, update the UI or state to reflect the change here
      fetchAllCompanies();
      toast.success('Status Updated Successfully');
  
    } catch (error) {
      console.error('Error updating company status:', error);
    }
  };
  

  return (
    <div className="content-wrapper">
      <ToastContainer />
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="row">
          <div className="form-group my-2 col-md-4">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          <div className="form-group my-2 col-md-4">
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          <div className="form-group my-2 col-md-4">
            <input
              type="text"
              name="owner_name"
              placeholder="Owner Name"
              value={formData.owner_name}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          <div className="form-group my-2 col-md-4">
            <input
              type="text"
              name="company_name"
              placeholder="Company Name"
              value={formData.company_name}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          <div className="form-group my-2 col-md-4">
            <input
              type="number"
              name="mobile"
              placeholder="Mobile"
              value={formData.mobile}
              onChange={handleChange}
              className="form-control"
              required
              onInput={enforceMaxLength}
            />
          </div>
          <div className="form-group my-2 col-md-4">
            <input
              type="text"
              name="user_name"
              placeholder="User Name"
              value={formData.user_name}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          <div className="form-group my-2 col-md-4" hidden>
            <input
              type="text"
              name="super_admin"
              placeholder="Super Admin"
              value={formData.super_admin}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          <div className="form-group my-2 col-md-4">
            <input
              type="file"
              name="logo"
              onChange={handleFileChange}
              className="form-control"
              accept="image/*"
            />
          </div>
          <div className="form-group my-2 col-md-4">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? "Creating..." : "Create Company"}
            </button>
          </div>
        </div>
      </form>

      <hr />

      <div className="text-center">
        <h4>Registered Companies</h4>
        <div className="scroll-table">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Logo</th>
                <th>Company Name</th>
                <th>Email</th>
                <th>Owner Name</th>
                <th>Mobile</th>
                <th>Role</th>
                <th>Employees</th>
                <th>Customers</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {companies.map((company) => (
                <tr
                  key={company.company_id}
                >
                  <td>
                    {company.logo ? (
                      <img
                        className="img-box"
                        src={`http://127.0.0.1:8000/${company.logo}`}
                        alt="logo"
                      />
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td className="mouse-pointer hover-bg" onClick={() => checkCompany(company.company_id)}>{company.company_name}</td>
                  <td>{company.email}</td>
                  <td>{company.owner_name}</td>
                  <td>{company.mobile}</td>
                  <td>{company.role}</td>
                  <td>{company.employees.length}</td>
                  <td>{company.customers.length}</td>
                  <td>
                    <button
                      className={`btn ${
                        company.active === 0 ? "btn-success" : "btn-danger"
                      } w-100`}
                      onClick={() => handleStatusChange(company.company_id, company.active)}
                    >
                      {company.active === 1
                        ? "Deactive"
                        : company.active === 0
                        ? "Active"
                        : "-"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
