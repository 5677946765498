import React from "react";
import { usePermissions } from "../App";

export default function Profile() {
  const { role } = usePermissions();
  return (
    <div className="content-wrapper">
      <h2>Profile Page</h2>
      <p>Permissions: {role}</p>
    </div>
  );
}
