import React, { createContext, useState, useEffect } from "react";
import { PROFILE_DETAILS, LOGOUT_URL } from "../Config/api";

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(PROFILE_DETAILS, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch profile details");
        }

        const data = await response.json();
        // console.log(data);
        setProfile(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleLogout = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    const accessToken = localStorage.getItem("accessToken");

    try {
      await fetch(LOGOUT_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refresh: refreshToken, access: accessToken }),
      });
    } catch (err) {
      console.error("Failed to log out:", err);
    } finally {
      // localStorage.clear();
      // localStorage.removeItem("selectedExpiry");
      // localStorage.removeItem("role");
      // localStorage.removeItem("refreshToken");
      // localStorage.removeItem("accessToken");
      // localStorage.removeItem("company_id");
      localStorage.clear();

      window.location.href = "/login";
    }
  };

  return (
    <ProfileContext.Provider value={{ profile, loading, error, handleLogout }}>
      {children}
    </ProfileContext.Provider>
  );
};
