import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  SEND_CUSTOMER_LINK,
  COMPANY_ALL_CUSTOMER,
  ASSIGN_EMPLOYEE_CUSTOMER,
  SHOW_COMPANY_EMPLOYEE,
  CUSTOMER_PLAN,
} from "../../Config/api";

export default function Customer() {
  const [formData, setFormData] = useState({
    email: "",
    company_id: "",
    name: "",
  });
  const [loading, setLoading] = useState(false);
  const [customersList, setCustomersList] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);
  const [editingCustomer, setEditingCustomer] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [editingCustomerPlan, setEditingCustomerPlan] = useState(null);

  useEffect(() => {
    const company_id = localStorage.getItem("company_id");
    if (company_id) {
      setFormData((prevData) => ({
        ...prevData,
        company_id: company_id,
      }));
    } else {
      toast.error("Company ID not found in local storage");
    }
  }, []);

  useEffect(() => {
    fetchAllCustomers();
    fetchAllEmployees();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(SEND_CUSTOMER_LINK, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Email sent successfully!");
      } else {
        toast.error("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending customer link:", error);
      toast.error("Error occurred while sending email");
    } finally {
      setLoading(false);
    }
  };

  const fetchAllCustomers = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await fetch(COMPANY_ALL_CUSTOMER, requestOptions);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setCustomersList(data.customers);
      } else {
        console.error("Failed to fetch customers:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchAllEmployees = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await fetch(SHOW_COMPANY_EMPLOYEE, requestOptions);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setEmployeesList(data);
      } else {
        console.error("Failed to fetch employees:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleAssignEmployee = async (
    customer_id,
    employee_id,
    platform_type
  ) => {
    const accessToken = localStorage.getItem("accessToken");
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ customer_id, employee_id, platform_type }),
    };

    try {
      const response = await fetch(ASSIGN_EMPLOYEE_CUSTOMER, requestOptions);
      if (response.ok) {
        toast.success("New Employee is Assigned!");
        fetchAllCustomers();
        setEditingCustomer(null); // Reset editing state
      } else {
        toast.error("Failed to assign employee");
      }
    } catch (error) {
      console.error("Error assigning employee:", error);
      toast.error("Error occurred while assigning employee");
    }
  };

  const handlePlanChange = async (
    customer_id,
    new_plan,
    customer_platformType
  ) => {
    console.log(customer_id, new_plan, customer_platformType);
    const accessToken = localStorage.getItem("accessToken");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ customer_id, new_plan, customer_platformType }),
    };

    try {
      const response = await fetch(CUSTOMER_PLAN, requestOptions);
      if (response.ok) {
        toast.success("Plan updated successfully!");
        fetchAllCustomers(); // Refresh the customers list
        setEditingCustomerPlan(null);
      } else {
        toast.error("Failed to update plan");
      }
    } catch (error) {
      console.error("Error updating plan:", error);
      toast.error("Error occurred while updating plan");
    }
  };

  // sorting
  const [searchQuery, setSearchQuery] = useState("");
  const [sortPlatform, setSortPlatform] = useState("");
  const [sortPlan, setSortPlan] = useState("");
  const [sortStatus, setSortStatus] = useState("");

  const filteredCustomers = customersList
    .filter((customer) =>
      customer.customer_name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter((customer) =>
      sortPlatform ? customer.customer_platformType === sortPlatform : true
    )
    .filter((customer) => (sortPlan ? customer.plan === sortPlan : true))
    .filter((customer) =>
      sortStatus ? customer.customer_status === sortStatus : true
    );

  return (
    <div className="content-wrapper">
      <form onSubmit={handleSubmit}>
        <h4 className="mb-3 text-center">Add New Customer</h4>
        <div className="row">
          <div className="form-group my-2 col-md-5">
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Customer Name"
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          <div className="form-group my-2 col-md-5">
            <input
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>

          <div className="form-group my-2 col-md-2">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? "Creating..." : "Send Mail"}
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />

      <div className="my-3">
        <h4 className="mb-3 text-center">All Customers</h4>

        <div className="d-flex mb-3">
          <input
            type="text"
            className="form-control mr-2"
            placeholder="Search by Name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <select
            className="form-control mr-2"
            value={sortPlatform}
            onChange={(e) => setSortPlatform(e.target.value)}
          >
            <option value="">All Platforms</option>
            <option value="AngelOne">AngelOne</option>
            <option value="Upstox">Upstox</option>
          </select>
          <select
            className="form-control mr-2"
            value={sortPlan}
            onChange={(e) => setSortPlan(e.target.value)}
          >
            <option value="">All Plans</option>
            <option value="NOPLAN">No Plan</option>
            <option value="demo">Demo</option>
            <option value="1month">1 Month</option>
            <option value="3month">3 Months</option>
            <option value="6month">6 Months</option>
            <option value="12month">12 Months</option>
          </select>
          <select
            className="form-control"
            value={sortStatus}
            onChange={(e) => setSortStatus(e.target.value)}
          >
            <option value="">Status</option>
            <option value="ACTIVE">Active</option>
            <option value="DEACTIVE">Deactive</option>
          </select>
        </div>

        {customersList.length > 0 ? (
          <div className="customerTable">
            <table className="table text-center">
              <thead>
                <tr>
                  <th>Customer Name</th>
                  <th>Customer Platform</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  {/* <th>Role</th> */}
                  <th>Plan</th>
                  <th>Status</th>
                  <th>Assign Employee</th>
                </tr>
              </thead>
              <tbody>
                {filteredCustomers.map((customer) => (
                  <tr key={customer.customer_id}>
                    <td>{customer.customer_name}</td>
                    {/* <td>{customer.customer_platformType}</td> */}
                    <td>
                    <div className="platform-logo-container">
                          {customer.customer_platformType === "AngelOne" ? (
                            <img
                              className="text-center my-1 platform-logo"
                              src="https://cdn.angelone.in/sparkweb/images/angel-one-logo.png"
                              alt={customer.customer_platformType}
                            />
                          ) : customer.customer_platformType === "Upstox" ? (
                            <img
                              className="text-center my-1 platform-logo"
                              src="https://upstox.com/app/themes/upstox/dist/img/logo/desktop-logo.svg"
                              alt={customer.customer_platformType}
                            />
                          ) : (
                            customer.customer_platformType
                          )}
                        </div>
                    </td>
                    <td>{customer.customer_email}</td>
                    <td>{customer.mobile}</td>
                    <td>
                        {editingCustomerPlan === customer.customer_id ? (
                          <div className="d-flex align-items-center">
                            <select
                              className="form-control w-fit-content mr-2"
                              value={
                                selectedPlan[customer.customer_id] ||
                                customer.plan
                              }
                              onChange={(e) => {
                                const newPlan = e.target.value;
                                setSelectedPlan({
                                  ...selectedPlan,
                                  [customer.customer_id]: newPlan,
                                });
                                handlePlanChange(
                                  customer.customer_id,
                                  newPlan,
                                  customer.customer_platformType
                                );
                              }}
                            >
                              <option value="NOPLAN">NO PLAN</option>
                              <option value="demo">Demo</option>
                              <option value="1month">1 Month</option>
                              <option value="3month">3 Months</option>
                              <option value="6month">6 Months</option>
                              <option value="12month">12 Months</option>
                            </select>
                            <button
                              className="btn btn-secondary btn-sm"
                              onClick={() => setEditingCustomerPlan(null)}
                            >
                              <i className="py-2 fa fa-times" />
                            </button>
                          </div>
                        ) : (
                          <div>
                            {customer.plan === "NOPLAN"
                              ? "No Plan"
                              : customer.plan === "demo"
                              ? "Demo"
                              : customer.plan === "1month"
                              ? "1 Month"
                              : customer.plan === "3month"
                              ? "3 Months"
                              : customer.plan === "6month"
                              ? "6 Months"
                              : customer.plan === "12month"
                              ? "12 Months"
                              : ""}

                            <i
                              className="fa fa-edit ml-2"
                              onClick={() =>
                                setEditingCustomerPlan(customer.customer_id)
                              }
                              style={{ cursor: "pointer" }}
                            ></i>
                          </div>
                        )}
                      </td>
                    <td>
                      <button
                        className={
                          customer.customer_status === "ACTIVE"
                            ? "btn btn-success w-100"
                            : "btn btn-danger w-100"
                        }
                      >
                        {customer.customer_status}
                      </button>
                    </td>
                    {/* <td>
                      {employeesList.length > 0
                        ? customer.employee_fk
                          ? employeesList.find(
                              (emp) => emp.employee_id === customer.employee_fk
                            )?.employee_name || "Unknown"
                          : "No Employee Assigned"
                        : "Loading employees..."}
                    </td> */}
                     <td>
                      {employeesList.length > 0 ? (
                        editingCustomer === customer.customer_id ? (
                          <select
                            className="form-control w-fit-content"
                            onChange={(e) =>
                              handleAssignEmployee(
                                customer.customer_id,
                                e.target.value,
                                customer.customer_platformType
                              )
                            }
                            defaultValue=""
                          >
                            <option value="" disabled>
                              Select Employee
                            </option>
                            {employeesList.map((employee) => (
                              <option
                                key={employee.employee_id}
                                value={employee.employee_id}
                                disabled={
                                  customer.employee_fk === employee.employee_id
                                }
                              >
                                {employee.employee_name}
                              </option>
                            ))}
                          </select>
                        ) : customer.employee_fk ? (
                          <div>
                            {employeesList.find(
                              (emp) => emp.employee_id === customer.employee_fk
                            )?.employee_name || "Unknown"}
                            <i
                              className="fa fa-edit ml-2"
                              onClick={() =>
                                setEditingCustomer(customer.customer_id)
                              }
                              style={{ cursor: "pointer" }}
                            ></i>
                          </div>
                        ) : (
                          <select
                            className="form-control"
                            onChange={(e) =>
                              handleAssignEmployee(
                                customer.customer_id,
                                e.target.value,
                                customer.customer_platformType
                              )
                            }
                            defaultValue=""
                          >
                            <option value="" disabled>
                              Select Employee
                            </option>
                            {employeesList.map((employee) => (
                              <option
                                key={employee.employee_id}
                                value={employee.employee_id}
                              >
                                {employee.employee_name}
                              </option>
                            ))}
                          </select>
                        )
                      ) : (
                        <span>Loading employees...</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tbody hidden>
                {customersList
                  .sort((a, b) => (a.customer_status === "ACTIVE" ? -1 : 1))
                  .map((customer) => (
                    <tr key={customer.customer_id}>
                      <td>{customer.customer_name}</td>
                      <td>{customer.customer_platformType}</td>
                      <td>{customer.customer_email}</td>
                      <td>{customer.mobile}</td>
                      <td>
                        {editingCustomerPlan === customer.customer_id ? (
                          <div className="d-flex align-items-center">
                            <select
                              className="form-control w-fit-content mr-2"
                              value={
                                selectedPlan[customer.customer_id] ||
                                customer.plan
                              }
                              onChange={(e) => {
                                const newPlan = e.target.value;
                                setSelectedPlan({
                                  ...selectedPlan,
                                  [customer.customer_id]: newPlan,
                                });
                                handlePlanChange(
                                  customer.customer_id,
                                  newPlan,
                                  customer.customer_platformType
                                );
                              }}
                            >
                              <option value="NOPLAN">NO PLAN</option>
                              <option value="demo">Demo</option>
                              <option value="1month">1 Month</option>
                              <option value="3month">3 Months</option>
                              <option value="6month">6 Months</option>
                              <option value="12month">12 Months</option>
                            </select>
                            <button
                              className="btn btn-secondary btn-sm"
                              onClick={() => setEditingCustomerPlan(null)}
                            >
                              <i className="py-2 fa fa-times" />
                            </button>
                          </div>
                        ) : (
                          <div>
                            {customer.plan === "NOPLAN"
                              ? "No Plan"
                              : customer.plan === "demo"
                              ? "Demo"
                              : customer.plan === "1month"
                              ? "1 Month"
                              : customer.plan === "3month"
                              ? "3 Months"
                              : customer.plan === "6month"
                              ? "6 Months"
                              : customer.plan === "12month"
                              ? "12 Months"
                              : ""}

                            <i
                              className="fa fa-edit ml-2"
                              onClick={() =>
                                setEditingCustomerPlan(customer.customer_id)
                              }
                              style={{ cursor: "pointer" }}
                            ></i>
                          </div>
                        )}
                      </td>

              
                      <td>
                        <button
                          className={
                            customer.customer_status === "ACTIVE"
                              ? "btn btn-success w-100"
                              : "btn btn-danger w-100"
                          }
                        >
                          {customer.customer_status}
                        </button>
                      </td>
                      {/* <td>
                      {employeesList.length > 0 ? (
                        editingCustomer === customer.customer_id ? (
                          <select
                            className="form-control w-fit-content"
                            onChange={(e) =>
                              handleAssignEmployee(
                                customer.customer_id,
                                e.target.value,
                                customer.customer_platformType
                              )
                            }
                            defaultValue=""
                          >
                            <option value="" disabled>
                              Select Employee
                            </option>
                            {employeesList.map((employee) => (
                              <option
                                key={employee.employee_id}
                                value={employee.employee_id}
                                disabled={
                                  customer.employee_fk === employee.employee_id
                                }
                              >
                                {employee.employee_name}
                              </option>
                            ))}
                          </select>
                        ) : customer.employee_fk ? (
                          <div>
                            {employeesList.find(
                              (emp) => emp.employee_id === customer.employee_fk
                            )?.employee_name || "Unknown"}
                            <i
                              className="fa fa-edit ml-2"
                              onClick={() =>
                                setEditingCustomer(customer.customer_id)
                              }
                              style={{ cursor: "pointer" }}
                            ></i>
                          </div>
                        ) : (
                          <select
                            className="form-control"
                            onChange={(e) =>
                              handleAssignEmployee(
                                customer.customer_id,
                                e.target.value,
                                customer.customer_platformType
                              )
                            }
                            defaultValue=""
                          >
                            <option value="" disabled>
                              Select Employee
                            </option>
                            {employeesList.map((employee) => (
                              <option
                                key={employee.employee_id}
                                value={employee.employee_id}
                              >
                                {employee.employee_name}
                              </option>
                            ))}
                          </select>
                        )
                      ) : (
                        <span>Loading employees...</span>
                      )}
                    </td> */}
                      <td>
                        {employeesList.length > 0 ? (
                          editingCustomer === customer.customer_id ? (
                            <div className="d-flex align-items-center">
                              <select
                                className="form-control w-fit-content mr-2"
                                onChange={(e) =>
                                  handleAssignEmployee(
                                    customer.customer_id,
                                    e.target.value,
                                    customer.customer_platformType
                                  )
                                }
                                defaultValue=""
                              >
                                <option value="" disabled>
                                  Select Employee
                                </option>
                                {employeesList.map((employee) => (
                                  <option
                                    key={employee.employee_id}
                                    value={employee.employee_id}
                                    disabled={
                                      customer.employee_fk ===
                                      employee.employee_id
                                    }
                                  >
                                    {employee.employee_name}
                                  </option>
                                ))}
                              </select>
                              <button
                                className="btn btn-secondary btn-sm"
                                onClick={() => setEditingCustomer(null)}
                              >
                                <i className="py-2 fa fa-times" />
                              </button>
                            </div>
                          ) : customer.employee_fk ? (
                            <div>
                              {employeesList.find(
                                (emp) =>
                                  emp.employee_id === customer.employee_fk
                              )?.employee_name || "Unknown"}
                              <i
                                className="fa fa-edit ml-2"
                                onClick={() =>
                                  setEditingCustomer(customer.customer_id)
                                }
                                style={{ cursor: "pointer" }}
                              ></i>
                            </div>
                          ) : (
                            <select
                              className="form-control"
                              onChange={(e) =>
                                handleAssignEmployee(
                                  customer.customer_id,
                                  e.target.value,
                                  customer.customer_platformType
                                )
                              }
                              defaultValue=""
                            >
                              <option value="" disabled>
                                Select Employee
                              </option>
                              {employeesList.map((employee) => (
                                <option
                                  key={employee.employee_id}
                                  value={employee.employee_id}
                                >
                                  {employee.employee_name}
                                </option>
                              ))}
                            </select>
                          )
                        ) : (
                          <span>Loading employees...</span>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No customers found</p>
        )}
      </div>
    </div>
  );
}
