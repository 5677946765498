class WebSocketService {
    constructor(feedToken, onDataReceived) {
        this.clientCode = 'Z3722'; // Replace with your Angel One Client Code
        this.apiKey = 'oGShibhB';
        this.feedToken = feedToken;
        this.wsUrl = `wss://smartapisocket.angelone.in/smart-stream?clientCode=${this.clientCode}&feedToken=${this.feedToken}&apiKey=${this.apiKey}`;
        this.socket = null;
        this.onDataReceived = onDataReceived;

        this.init();
    }

    init() {
        this.socket = new WebSocket(this.wsUrl);
        this.socket.binaryType = 'arraybuffer'; // Ensure WebSocket receives data as ArrayBuffer

        this.socket.onopen = () => {
            console.log('WebSocket connection opened.');
            this.subscribeToTokens();
        };

        this.socket.onmessage = (event) => {
            this.handleMessage(event.data);
        };

        this.socket.onclose = () => {
            console.log('WebSocket connection closed.');
        };

        this.socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        setInterval(() => {
            this.socket.send('ping');
        }, 30000);
    }

    
    subscribeToTokens() {

        const tokens = ["99926009", "99926037", "99926074", "99926000", "99919000"];
        const tokenList = tokens.map(token => ({
            exchangeType: token === "99919000" ? 3 : 1,
            tokens: [token]
        }));

        const requestData = {
            "correlationID": "abcde12345",
            "action": 1,
            "params": {
                "mode": 3, // Make sure this is the correct mode for your use case
                "tokenList": tokenList
            }
        };

        this.socket.send(JSON.stringify(requestData));
    }

    handleMessage(data) {
        if (data instanceof ArrayBuffer) {
            const dataView = new DataView(data);
            const subscriptionMode = dataView.getInt8(0);
            const exchangeType = dataView.getInt8(1);
            const tokenIdBytes = new Uint8Array(data, 2, 25);
            const tokenId = new TextDecoder().decode(tokenIdBytes).replace(/\0/g, '');
            const sequenceNumber = dataView.getBigInt64(27, true);
            const exchangeTimestamp = dataView.getBigInt64(35, true);
            const lastTradedPrice = dataView.getInt32(43, true);
            const lastTradedQuantity = dataView.getBigInt64(51, true);
            const averageTradedPrice = dataView.getBigInt64(59, true);
            const volumeTraded = dataView.getBigInt64(67, true);
            const totalBuyQuantity = dataView.getFloat64(75, true);
            const totalSellQuantity = dataView.getFloat64(83, true);
            const openPrice = dataView.getBigInt64(91, true);
            const highPrice = dataView.getBigInt64(99, true);
            const lowPrice = dataView.getBigInt64(107, true);
            const closePrice = dataView.getBigInt64(115, true);
            const lastTradedTimestamp = dataView.getBigInt64(123, true);
            const openInterest = dataView.getBigInt64(131, true);
            

            this.onDataReceived({
                subscriptionMode,
                exchangeType,
                tokenId,
                sequenceNumber,
                exchangeTimestamp,
                lastTradedPrice,
                lastTradedQuantity,
                averageTradedPrice,
                volumeTraded,
                totalBuyQuantity,
                totalSellQuantity,
                openPrice,
                highPrice,
                lowPrice,
                closePrice,
                lastTradedTimestamp,
                openInterest,
            });
        } else {
            console.error('Received data is not of type ArrayBuffer.');
        }
    }
}

export default WebSocketService;
