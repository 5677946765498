import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ALL_CUSTOMER_EMPLOYEE } from "../../Config/api";
import EmployeeCustomerList from "./EmployeeCustomerList";

export default function EmployeeCustomer() {
  return (
    <div className="content-wrapper">
      <EmployeeCustomerList />
    </div>
  );
}
