import React, { useState, useEffect } from "react";
import axios from "axios";
import ApexCharts from "react-apexcharts";
import { useParams, useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

const CandleGraph = () => {
  const { id: tokenSymbol, exch_seg } = useParams();
  const [candleData, setCandleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCandleData = async (symbolToken) => {
      try {
        const storedToken = localStorage.getItem("angelToken");
        const dataApi = localStorage.getItem("dataApi");
        if (!storedToken) {
          throw new Error("Token not found in local storage. Please login.");
        }
        const apiKey = dataApi;
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const hours = String(today.getHours()).padStart(2, '0');
        const minutes = String(today.getMinutes()).padStart(2, '0');
        
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
        const fromDate = `${year}-${month}-${day} 09:15`
        
        const data = {
          exchange: exch_seg,
          symboltoken: symbolToken,
          interval: "ONE_MINUTE",
          fromdate: fromDate,
          todate: formattedDate,
        };

        const config = {
          headers: {
            "X-PrivateKey": apiKey,
            Accept: "application/json",
            "X-SourceID": "WEB",
            "X-ClientLocalIP": "192.168.168.168",
            "X-ClientPublicIP": "106.193.147.98",
            "X-MACAddress": "fe80::216e:6507:4b90:3719",
            "X-UserType": "USER",
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        };

        const response = await axios.post(
          "https://apiconnect.angelbroking.com/rest/secure/angelbroking/historical/v1/getCandleData",
          data,
          config
        );
        console.log("Candle data fetched successfully");
        console.log(response.data)
        setCandleData(response.data);
      } catch (error) {
        console.log("Failed to fetch candle data");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCandleData(tokenSymbol);
    
    const intervalId = setInterval(() => {
      fetchCandleData(tokenSymbol);
    }, 6000); // Fetch data every minute

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [tokenSymbol, exch_seg]);

  useEffect(() => {
    const fetchName = async () => {
      try {
        const response = await axios.get(
          "https://margincalculator.angelbroking.com/OpenAPI_File/files/OpenAPIScripMaster.json"
        );
        const data = response.data;
        const item = data.find(
          (el) => el.token === tokenSymbol && el.exch_seg === exch_seg
        );
        if (item) {
          setName(item.name);
        }
      } catch (error) {
        console.log("Failed to fetch name");
        console.error(error);
      }
    };

    fetchName();
  }, [tokenSymbol, exch_seg]);

  const optionData = () => {
    if (name) {
      navigate(`/employee-data/options/${name}`);
    }
  };

  return (
    <div className="content-wrapper">
      <div className="container my-5">
        {loading ? (
          <div className="text-center">
            <TailSpin color="#00BFFF" height={80} width={80} />
          </div>
        ) : (
          <div id="chart">
            {candleData && (
              <div className="my-5">
                <ApexCharts
                  options={renderCandleChartOptions(candleData)}
                  series={renderCandleChartSeries(candleData)}
                  type="candlestick"
                  height={300}
                />
              </div>
            )}
          </div>
        )}
      </div>

      <button className="btn btn-primary" onClick={optionData}>
        Options
      </button>
    </div>
  );
};

const renderCandleChartOptions = (response) => {
  return {
    chart: {
      type: "candlestick",
      height: 500,
    },
    plotOptions: {
      candlestick: {
        colors: {
          upward: "#089981",
          downward: "#F23645",
        },
        wick: {
          useFillColor: true,
        },
      },
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
    },
    grid: {
      show: false, // Remove background grid
    },
    stroke: {
      curve: 'smooth',
    },
  };
};

const renderCandleChartSeries = (response) => {
  if (response && response.data && response.data.length > 0) {
    const candles = response.data;
    const seriesData = candles.map((candle) => ({
      x: new Date(candle[0]),
      y: [candle[1], candle[2], candle[3], candle[4], candle[5]],
    }));

    // Ensure there are no gaps in the data by interpolating missing points
    const completeSeriesData = fillMissingData(seriesData);

    return [
      {
        data: completeSeriesData,
      },
    ];
  } else {
    console.log("No candle data available.");
    return [];
  }
};

const fillMissingData = (data) => {
  const completeData = [];
  const timeStep = 60000; // 1 minute in milliseconds

  for (let i = 0; i < data.length - 1; i++) {
    completeData.push(data[i]);
    const currentDate = data[i].x;
    const nextDate = data[i + 1].x;

    let nextExpectedDate = new Date(currentDate.getTime() + timeStep);

    while (nextExpectedDate < nextDate) {
      completeData.push({
        x: nextExpectedDate,
        y: data[i].y, // Repeat the previous value
      });
      nextExpectedDate = new Date(nextExpectedDate.getTime() + timeStep);
    }
  }

  completeData.push(data[data.length - 1]); // Add the last data point
  return completeData;
};

export default CandleGraph;
