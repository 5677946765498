import React, { useEffect, useState } from "react";
import {
  SUBADMIN_PROFILE,
  IMG_URL,
  SUBADMIN_PASSWORD,
} from "../../Config/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export default function SubAdminProfile() {
  const [profileData, setProfileData] = useState({
    owner_name: "",
    logo: "",
    company_name: "",
    mobile: "",
    user_name: "",
  });

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      fetch(SUBADMIN_PROFILE, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setProfileData(data);
        })
        .catch((error) => console.error("Error fetching profile data:", error));
    }
  }, []);

  const [form, setForm] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [showPassword, setShowPassword] = useState({
    old_password: false,
    new_password: false,
    confirm_password: false,
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setForm({
      ...form,
      [id]: value,
    });
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevShowPassword) => ({
      ...prevShowPassword,
      [field]: !prevShowPassword[field],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { old_password, new_password, confirm_password } = form;

    if (new_password !== confirm_password) {
      toast.error("New Password and Confirm New Password do not match!");
      return;
    }

    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        SUBADMIN_PASSWORD,
        {
          old_password,
          new_password,
          confirm_password,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      toast.success(response.data.detail);
      setForm({
        old_password: "",
        new_password: "",
        confirm_password: "",
      });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.detail);
      } else {
        toast.error("An error occurred while updating the password.");
      }
    }
  };

  return (
    <div className="content-wrapper">
      <ToastContainer />
      <div className="container">
        <div className="d-flex justify-content-space-between align-items-center">
          <div className="form-group my-2">
            {profileData.logo ? (
              <img
                className="logo-img-company"
                src={IMG_URL + profileData.logo}
                alt="Company Logo"
              />
            ) : (
              ""
            )}
          </div>
          <div>
            <button
              className="btn btn-danger mx-1"
              data-toggle="modal"
              data-target="#changePassword"
            >
              Change Password
            </button>

            <div
              class="modal fade"
              id="changePassword"
              tabindex="-1"
              role="dialog"
              aria-labelledby="changePasswordLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="changePasswordLabel">
                      Change Password
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <form
                      className="row profileContainer change-password-container"
                      onSubmit={handleSubmit}
                    >
                      <div className="form-group col-md-12">
                        <label htmlFor="old_password">Current Password</label>
                        <div className="input-group">
                          <input
                            type={
                              showPassword.old_password ? "text" : "password"
                            }
                            className="form-control"
                            id="old_password"
                            placeholder="Current Password"
                            value={form.old_password}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={() =>
                                togglePasswordVisibility("old_password")
                              }
                            >
                              <i
                                className={
                                  showPassword.old_password
                                    ? "fa fa-eye-slash"
                                    : "fa fa-eye"
                                }
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="new_password">New Password</label>
                        <div className="input-group">
                          <input
                            type={
                              showPassword.new_password ? "text" : "password"
                            }
                            className="form-control"
                            id="new_password"
                            placeholder="New Password"
                            value={form.new_password}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={() =>
                                togglePasswordVisibility("new_password")
                              }
                            >
                              <i
                                className={
                                  showPassword.new_password
                                    ? "fa fa-eye-slash"
                                    : "fa fa-eye"
                                }
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="confirm_password">
                          Confirm New Password
                        </label>
                        <div className="input-group">
                          <input
                            type={
                              showPassword.confirm_password
                                ? "text"
                                : "password"
                            }
                            className="form-control"
                            id="confirm_password"
                            placeholder="Confirm New Password"
                            value={form.confirm_password}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={() =>
                                togglePasswordVisibility("confirm_password")
                              }
                            >
                              <i
                                className={
                                  showPassword.confirm_password
                                    ? "fa fa-eye-slash"
                                    : "fa fa-eye"
                                }
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        <button type="submit" className="btn btn-primary">
                          Update Password
                        </button>
                      </div>
                    </form>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form className="row profileContainer">
          <div className="form-group my-2 col-md-6">
            <label htmlFor="name">Name</label>
            <p>{profileData.employee_name}</p>
          </div>
          <div className="form-group my-2 col-md-6">
            <label htmlFor="userName">Username</label>
            <p>{profileData.user_name}</p>
          </div>
          <div className="form-group my-2 col-md-6">
            <label htmlFor="company_name">Company Name</label>
            <p>{profileData.company_name}</p>
          </div>
          <div className="form-group my-2 col-md-6">
            <label htmlFor="mobile">Mobile</label>
            <p>{profileData.mobile}</p>
          </div>
          <div className="form-group my-2 col-md-6">
            <label htmlFor="email">Email</label>
            <p>{profileData.email}</p>
          </div>
        </form>
      </div>
    </div>
  );
}
