import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  ALL_CUSTOMER_EMPLOYEE,
  TRADE_BY_GROUP,
  SHOW_GROUP_WITH_CUSTOMER,
  TRADE_SAVE_HISTORY,
} from "../../Config/api";

const Options = () => {
  const { name } = useParams();
  const [jsonData, setJsonData] = useState([]);
  const [expiryDates, setExpiryDates] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [livePrices, setLivePrices] = useState({});
  const [selectedExpiry, setSelectedExpiry] = useState(
    localStorage.getItem("selectedExpiry") || ""
  );
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTokenDetails, setSelectedTokenDetails] = useState(null);
  const [lotSizeInput, setLotSizeInput] = useState(1);
  const [orderType, setOrderType] = useState("Market");
  const [limitPrice, setLimitPrice] = useState("");
  const initialLoad = useRef(true);
  const [customersList, setCustomersList] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [amxidxToken, setAmxidxToken] = useState(null);

  useEffect(() => {
    fetch(
      "https://margincalculator.angelbroking.com/OpenAPI_File/files/OpenAPIScripMaster.json"
    )
      .then((response) => response.json())
      .then((data) => {
        const filteredJsonData = data.filter(
          // (item) => item.name === name && item.instrumenttype === "OPTIDX"
          (item) =>
            item.name === name &&
            (item.instrumenttype === "OPTIDX" ||
              item.instrumenttype === "OPTSTK")
        );
        setJsonData(filteredJsonData);

        // working old
        // const uniqueExpiries = Array.from(
        //   new Set(filteredJsonData.map((item) => item.expiry))
        // );
        // setExpiryDates(uniqueExpiries);
        // working old

        // new expiry with filter
        function getCurrentAndNextMonthExpiries(data) {
          // Get current date
          const today = new Date();
          const currentMonth = today.getMonth(); // 0-indexed month (0 = January, 1 = February, ..., 11 = December)
          const currentYear = today.getFullYear();

          // Get next month
          const nextMonthDate = new Date(today.setMonth(currentMonth + 1));
          const nextMonth = nextMonthDate.getMonth();
          const nextMonthYear = nextMonthDate.getFullYear();

          // Month map to convert month abbreviation to number
          const monthMap = {
            JAN: 0,
            FEB: 1,
            MAR: 2,
            APR: 3,
            MAY: 4,
            JUN: 5,
            JUL: 6,
            AUG: 7,
            SEP: 8,
            OCT: 9,
            NOV: 10,
            DEC: 11,
          };

          // Filter the expiries
          const filteredExpiries = data
            .map((item) => item.expiry)
            .filter((expiry) => {
              const day = parseInt(expiry.substring(0, 2), 10);
              const monthAbbr = expiry.substring(2, 5);
              const year = parseInt(expiry.substring(5, 9), 10);
              const month = monthMap[monthAbbr];

              // Check if the expiry date is in the current month or next month
              return (
                (month === currentMonth && year === currentYear) ||
                (month === nextMonth && year === nextMonthYear)
              );
            });

          // Get unique expiries and sort them in ascending order
          const uniqueExpiries = Array.from(new Set(filteredExpiries)).sort(
            (a, b) => {
              const dayA = parseInt(a.substring(0, 2), 10);
              const monthA = monthMap[a.substring(2, 5)];
              const yearA = parseInt(a.substring(5, 9), 10);

              const dayB = parseInt(b.substring(0, 2), 10);
              const monthB = monthMap[b.substring(2, 5)];
              const yearB = parseInt(b.substring(5, 9), 10);

              return (
                new Date(yearA, monthA, dayA) - new Date(yearB, monthB, dayB)
              );
            }
          );

          return uniqueExpiries;
        }

        const uniqueExpiries = getCurrentAndNextMonthExpiries(filteredJsonData);
        setExpiryDates(uniqueExpiries); // Use the unique expiries for setExpiryDates
        console.log(uniqueExpiries);
        // new expiry with filter

        // amidx instrument
        // const amxidxInstrument = data.find(
        //   (item) => item.name === name && item.instrumenttype === "AMXIDX"
        // );

        const amxidxInstrument = data.find(
          (item) =>
            item.name === name &&
            (item.instrumenttype === "AMXIDX" || item.symbol.endsWith("-EQ"))
        );

        if (amxidxInstrument) {
          setAmxidxToken(amxidxInstrument.token);
          const feedToken = localStorage.getItem("feedToken");
          if (feedToken) {
            establishWebSocket(feedToken, [
              {
                token: amxidxInstrument.token,
                exch_seg: amxidxInstrument.exch_seg,
              },
            ]);
          }
        }

        if (initialLoad.current) {
          initialLoad.current = false;
          const initialExpiry =
            selectedExpiry || (uniqueExpiries.length > 0 && uniqueExpiries[0]);
          if (initialExpiry) {
            setSelectedExpiry(initialExpiry);
            filterByExpiry(initialExpiry);
          }
        }
      })
      .catch((error) => console.error("Error fetching JSON data:", error));
  }, [name]);

  useEffect(() => {
    if (selectedExpiry) {
      filterByExpiry(selectedExpiry);
    }
  }, [selectedExpiry, jsonData]);

  const getExchangeType = (exch_seg) => {
    switch (exch_seg) {
      case "NSE":
        return 1;
      case "NFO":
        return 2;
      case "BSE":
        return 3;
      case "BFO":
        return 4;
      default:
        return null;
    }
  };

  const filterByExpiry = (expiry) => {
    setIsLoading(true);
    setSelectedExpiry(expiry);
    localStorage.setItem("selectedExpiry", expiry);

    const filtered = jsonData.filter(
      // (item) => item.expiry === expiry && item.instrumenttype === "OPTIDX"
      (item) =>
        item.expiry === expiry &&
        (item.instrumenttype === "OPTIDX" ||
          (item.instrumenttype === "OPTSTK" && item.exch_seg === "NFO"))
    );

    const ceData = filtered
      .filter((item) => item.symbol.endsWith("CE"))
      .sort((a, b) => a.strike - b.strike);
    const peData = filtered
      .filter((item) => item.symbol.endsWith("PE"))
      .sort((a, b) => a.strike - b.strike);

    const combinedData = [];
    let ceIndex = 0;
    let peIndex = 0;
    while (ceIndex < ceData.length || peIndex < peData.length) {
      const ceStrike =
        ceIndex < ceData.length
          ? ceData[ceIndex].strike
          : Number.POSITIVE_INFINITY;
      const peStrike =
        peIndex < peData.length
          ? peData[peIndex].strike
          : Number.POSITIVE_INFINITY;

      if (ceStrike === peStrike) {
        combinedData.push({
          ceSymbol: ceData[ceIndex].symbol,
          ceToken: ceData[ceIndex].token,
          ceExchSeg: ceData[ceIndex].exch_seg,
          strike: ceData[ceIndex].strike,
          peSymbol: peData[peIndex].symbol,
          peToken: peData[peIndex].token,
          peExchSeg: peData[peIndex].exch_seg,
        });
        ceIndex++;
        peIndex++;
      } else if (ceStrike < peStrike) {
        combinedData.push({
          ceSymbol: ceData[ceIndex].symbol,
          ceToken: ceData[ceIndex].token,
          ceExchSeg: ceData[ceIndex].exch_seg,
          strike: ceData[ceIndex].strike,
          peSymbol: "",
          peToken: "",
          peExchSeg: "",
        });
        ceIndex++;
      } else {
        combinedData.push({
          ceSymbol: "",
          ceToken: "",
          ceExchSeg: "",
          strike: peData[peIndex].strike,
          peSymbol: peData[peIndex].symbol,
          peToken: peData[peIndex].token,
          peExchSeg: peData[peIndex].exch_seg,
        });
        peIndex++;
      }
    }

    setFilteredData(combinedData);
    const ceTokens = combinedData
      .filter((item) => item.ceToken)
      .map((item) => ({ token: item.ceToken, exch_seg: item.ceExchSeg }));
    const peTokens = combinedData
      .filter((item) => item.peToken)
      .map((item) => ({ token: item.peToken, exch_seg: item.peExchSeg }));
    const tokens = ceTokens.concat(peTokens);

    const feedToken = localStorage.getItem("feedToken");
    if (feedToken) {
      establishWebSocket(feedToken, tokens);
    } else {
      console.error(
        "Feed token not found in localStorage. Please login first."
      );
    }

    setTimeout(() => {
      setIsLoading(false);
      scrollStrike();
    }, 1000);
  };

  const establishWebSocket = (feedToken, tokens) => {
    const clientCode = "Z3722"; // Replace with your Angel One Client Code
    const apiKey = "oGShibhB";
    const wsUrl = `wss://smartapisocket.angelone.in/smart-stream?clientCode=${clientCode}&feedToken=${feedToken}&apiKey=${apiKey}`;
    const socket = new WebSocket(wsUrl);

    socket.onopen = () => {
      console.log("WebSocket connection opened.");
      subscribeToTokens(socket, tokens);
    };

    socket.onmessage = (event) => {
      if (event.data instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
          const dataView = new DataView(reader.result);
          const tokenIdBytes = new Uint8Array(reader.result, 2, 25);
          const tokenId = new TextDecoder()
            .decode(tokenIdBytes)
            .replace(/\0/g, "");
          const lastTradedPrice = dataView.getInt32(43, true);

          setLivePrices((prevState) => ({
            ...prevState,
            [tokenId]: lastTradedPrice / 100,
          }));
        };
        reader.readAsArrayBuffer(event.data);
      }
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed.");
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    setInterval(() => {
      socket.send("ping");
    }, 30000);
  };

  const subscribeToTokens = (socket, tokens) => {
    const requestData = {
      correlationID: "abcde12345",
      action: 1,
      params: {
        mode: 3,
        tokenList: tokens.map((tokenObj) => ({
          exchangeType: getExchangeType(tokenObj.exch_seg),
          tokens: [tokenObj.token],
        })),
      },
    };

    socket.send(JSON.stringify(requestData));
  };

  const trade = (symbol) => {
    const tokenDetails = jsonData.find((item) => item.symbol === symbol);
    if (tokenDetails) {
      setSelectedTokenDetails({
        ...tokenDetails,
        livePrice: livePrices[tokenDetails.token],
      });
      setLotSizeInput(1); // Reset lot size input to 1
      const modal = new window.bootstrap.Modal(
        document.getElementById("tradeModal")
      );
      modal.show();
    }
  };

  const handleLotSizeChange = (e) => {
    setLotSizeInput(e.target.value);
  };

  const handleOrderTypeChange = (e) => {
    setOrderType(e.target.value);
  };

  const handleLimitPriceChange = (e) => {
    setLimitPrice(e.target.value);
  };

  // here
  const [groups, setGroups] = useState([]);
  const [expandedGroup, setExpandedGroup] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [customersForGroup, setCustomersForGroup] = useState([]);
  const fetchGroups = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await fetch(SHOW_GROUP_WITH_CUSTOMER, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setGroups(data || []);
      } else {
        throw new Error("Failed to fetch groups");
      }
    } catch (error) {
      console.error("Error fetching groups:", error.message);
      toast.error("Failed to fetch groups. Please try again.");
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const [selectedGroup, setSelectedGroup] = useState("");

  const customerInGroup = async (groupName) => {
    console.log(groupName);
    setSelectedGroup(groupName);

    try {
      setExpandedGroup(groupName);
      const accessToken = localStorage.getItem("accessToken");
      const response = await fetch(
        `${SHOW_GROUP_WITH_CUSTOMER}?group_name=${groupName}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const customersForGroup = data.filter(
          (item) =>
            item.group_name === groupName && item.customer_status === "ACTIVE"
        );

        console.log(customersForGroup);
        setCustomersForGroup(customersForGroup);
        setShowModal(true);
      } else {
        throw new Error("Failed to fetch customers for the group");
      }
    } catch (error) {
      console.error("Error fetching customers for group:", error.message);
      toast.error("Failed to fetch customers for the group. Please try again.");
    }
  };

  // here is final trade

  const finalTrade = async () => {
    console.log("1");
    // if (!selectedCustomers.length) {
    //   toast.error("Please select a customer from the group.");
    //   return;
    // }

    const transactionType = document.getElementById("transactionType").value;
    const orderType = document.getElementById("orderTypeSelect").value;

    const payload = customersForGroup.map((customer) => {
      let quantity;
      if (customer.option === "index" && name === "BANKNIFTY") {
        quantity = selectedTokenDetails.lotsize * customer.BANKNIFTY;
      } else if (customer.option === "index" && name === "NIFTY") {
        quantity = selectedTokenDetails.lotsize * customer.NIFTY50;
      } else if (customer.option === "index" && name === "FINNIFTY") {
        quantity = selectedTokenDetails.lotsize * customer.FINNIFTY;
      } else if (customer.option === "index" && name === "MIDCPNIFTY") {
        quantity = selectedTokenDetails.lotsize * customer.MIDCPNIFTY;
      } else if (customer.option === "index" && name === "SENSEX") {
        quantity = selectedTokenDetails.lotsize * customer.SENSEX;
      } else if (customer.option === "stock") {
        quantity = selectedTokenDetails.lotsize * customer.lot_size;
      } else {
        // quantity = 0;
        toast.error("Please Select Proper Group");
        return null;
      }
      if (quantity === undefined || quantity <= 0) {
        toast.error("Quantity is undefined or invalid");
        return null; // Return null to filter out this entry
      }
      return {
        customer_id: customer.customer_uid,
        transaction_type: transactionType,
        quantity: quantity,
        symbol_token: selectedTokenDetails.token,
        trading_symbol: selectedTokenDetails.symbol,
        exchange: selectedTokenDetails.exch_seg,
        ordertype: orderType,
        ...(orderType === "LIMIT" && { price: limitPrice }), // Conditionally add the price property
      };
    });

    console.log(payload);

    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await fetch(TRADE_BY_GROUP, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      console.log(data.status);
      if (data.status !== "error") {
        // Trade successful

        toast.success("Trade successful!");
        // Optionally, you can close the modal here
        // setShowModal(false);

        const responses = data.responses;
        const accessToken = localStorage.getItem("accessToken"); // Retrieve the accessToken from local storage

        // Ensure accessToken is available
        if (!accessToken) {
          toast.error("Access token is missing.");
          return;
        }

        // Prepare trade history data
        const tradeHistoryData = responses.map((responseItem) => ({
          trade_id: responseItem.trade_id,
          customer_id: responseItem.customer_id,
          group_name: selectedGroup,
        }));

        // Call the function to save the trade history
        saveTradeHistory(tradeHistoryData, accessToken);
      } else {
        throw new Error("Failed to execute trade.");
      }
    } catch (error) {
      console.error("Error executing trade:", error.message);
      toast.error("Failed to execute trade. Please try again.");
    }
  };

  async function saveTradeHistory(tradeHistoryData, accessToken) {
    console.log("tradeHistoryData:", tradeHistoryData);
    console.log("Started Saving");

    try {
      // Make the API call to save the trade history
      const saveHistoryResponse = await fetch(TRADE_SAVE_HISTORY, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(tradeHistoryData),
      });

      if (saveHistoryResponse.ok) {
        // Show success message if the API call is successful
        toast.success("Order Saved!");
      } else {
        // Handle error if the API call is not successful
        const errorData = await saveHistoryResponse.json();
        toast.error(`Error saving order: ${errorData.message}`);
      }
    } catch (error) {
      // Handle any network or other errors
      console.error("Error:", error);
      toast.error("An error occurred while saving the order.");
    }
  }

  const livePrice = livePrices[amxidxToken];

  // Find the closest strike price index
  const closestStrikeIndex = filteredData.reduce(
    (closestIndex, data, index) => {
      const currentStrike = data.strike / 100;
      const closestStrike = filteredData[closestIndex]?.strike / 100;

      return Math.abs(currentStrike - livePrice) <
        Math.abs(closestStrike - livePrice)
        ? index
        : closestIndex;
    },
    0
  );

  // Ref to store the table row elements
  const rowRefs = useRef([]);

  // Effect to scroll to the closest strike row on first load
  useEffect(() => {
    if (rowRefs.current[closestStrikeIndex]) {
      rowRefs.current[closestStrikeIndex].scrollIntoView({
        behavior: "smooth",
      });
    }
  }, []);

  const scrollStrike = () => {
    const element = document.getElementById("highlight-strike");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <div className="content-wrapper mb-0">
      <ToastContainer />
      <div className="mb-3 mx-1">
        <button className="btn btn-secondary" onClick={() => scrollStrike()}>
          {" "}
          {name}: {livePrices[amxidxToken] || "N/A"}
        </button>
      </div>
      <div className="expiryBtn" id="expiryButtons">
        {expiryDates.map((expiry) => (
          <button
            className={`btn btn-primary mx-1 my-1 ${
              expiry === selectedExpiry ? "expiry-selected" : ""
            }`}
            key={expiry}
            onClick={() => filterByExpiry(expiry)}
          >
            {expiry}
          </button>
        ))}
      </div>
      <div id="result">
        {isLoading ? (
          <div className="loader text-center my-3">Loading...</div>
        ) : (
          <div className="option-table">
            <table className="table text-center" id="resultTable">
              <thead>
                <tr>
                  <th>Call Price</th>
                  <th>Strike Price</th>
                  <th>Put Price</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((data, index) => (
                  <tr
                    key={index}
                    id={index === closestStrikeIndex ? "highlight-strike" : ""}
                    className={
                      index === closestStrikeIndex ? "highlight-strike" : ""
                    }
                  >
                    <td
                      className="buy-trade"
                      id={`token-${data.ceToken}`}
                      onClick={() => trade(data.ceSymbol)}
                    >
                      {livePrices[data.ceToken] || "N/A"}
                    </td>
                    <td>
                      {data.strike / 100}
                      {/* {index === closestStrikeIndex ? (
                        <p className="current-price"> current price: {livePrices[amxidxToken]}</p>
                      ) : (
                        ""
                      )} */}
                    </td>
                    <td
                      className="buy-trade"
                      id={`token-${data.peToken}`}
                      onClick={() => trade(data.peSymbol)}
                    >
                      {livePrices[data.peToken] || "N/A"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Bootstrap Modal */}
      <div
        className="modal fade"
        id="tradeModal"
        tabIndex="-1"
        aria-labelledby="tradeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="tradeModalLabel">
                Trade Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {selectedTokenDetails && (
                <>
                  {/* <p><strong>Symbol:</strong> {selectedTokenDetails.symbol}</p> */}
                  {/* <p><strong>Token:</strong> x</p> */}

                  <div className="my-3">
                    <p>Buy Trade For:</p>
                    <select
                      className="form-control"
                      onChange={(event) => customerInGroup(event.target.value)}
                    >
                      <option selected disabled>
                        Select Group
                      </option>
                      {groups && groups.length > 0 ? (
                        groups.map((group) => (
                          <option
                            key={group.group_name}
                            value={group.group_name}
                          >
                            {group.group_name}
                          </option>
                        ))
                      ) : (
                        <option disabled>No Groups Found</option>
                      )}
                    </select>
                  </div>
                  <p>
                    <strong></strong> {selectedTokenDetails.name}
                  </p>
                  <p>
                    <strong>Exchange:</strong> {selectedTokenDetails.exch_seg}
                  </p>
                  <p>
                    <strong>Strike:</strong> {selectedTokenDetails.strike / 100}
                  </p>
                  <p>
                    <strong>Lot Size:</strong> {selectedTokenDetails.lotsize}
                  </p>
                  <p>
                    <strong>Lot Size:</strong> {selectedTokenDetails.expiry}
                  </p>
                  {/* <p><strong>Instrument Type:</strong> {selectedTokenDetails.instrumenttype}</p> */}
                  {/* <p><strong>Exchange Segment:</strong> {selectedTokenDetails.exch_seg}</p> */}
                  <p>
                    <strong>Live Price:</strong>{" "}
                    {selectedTokenDetails.livePrice || "N/A"}
                  </p>
                  {/* <div className="mb-3">
                    <label htmlFor="lotSizeInput" className="form-label">
                      <strong>Lot Size Input:</strong>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="lotSizeInput"
                      min={1}
                      max={5}
                      value={lotSizeInput}
                      onChange={handleLotSizeChange}
                    />
                  </div>
                  <p>
                    <strong>Total Lot Size:</strong>{" "}
                    {selectedTokenDetails.lotsize * lotSizeInput}
                  </p> */}
                  <div className="mb-3">
                    <label htmlFor="transactionType" className="form-label">
                      <strong>Transaction Type:</strong>
                    </label>
                    <select className="form-control" id="transactionType">
                      <option value="BUY">BUY</option>
                      <option value="SELL">SELL</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="orderTypeSelect" className="form-label">
                      <strong>Order Type:</strong>
                    </label>
                    <select
                      className="form-control"
                      id="orderTypeSelect"
                      value={orderType}
                      onChange={handleOrderTypeChange}
                    >
                      <option value="MARKET">Market</option>
                      <option value="LIMIT">Limit</option>
                    </select>
                  </div>
                  {orderType === "LIMIT" && (
                    <div className="mb-3">
                      <label htmlFor="limitPriceInput" className="form-label">
                        <strong>Limit Price:</strong>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="limitPriceInput"
                        value={limitPrice}
                        onChange={handleLimitPriceChange}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => finalTrade()}
              >
                Trade
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Options;
