import React, { useState, useEffect } from "react";
import { SUBADMIN_LINK_GENERATE, SHOW_LEADS } from "../../Config/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function LeadGen() {
  const [link, setLink] = useState("");
  const [leads, setLeads] = useState([]); // Ensure leads is initialized as an empty array
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLink = async () => {
      const accessToken = localStorage.getItem("accessToken");

      try {
        const response = await fetch(SUBADMIN_LINK_GENERATE, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch link");
        }

        const data = await response.json();
        setLink(data.registration_link); // Assuming the link is returned in the 'registration_link' field
      } catch (error) {
        console.error("Error fetching link:", error);
      }
    };

    const fetchLeads = async () => {
      const accessToken = localStorage.getItem("accessToken");

      try {
        const response = await fetch(SHOW_LEADS, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch leads");
        }

        const data = await response.json();
        setLeads(data || []); // Ensure data.leads is set, default to empty array if undefined
        setLoading(false);
      } catch (error) {
        console.error("Error fetching leads:", error);
        setLoading(false);
      }
    };

    fetchLink();
    fetchLeads();
  }, []);

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("Link Copied");
      })
      .catch((err) => {
        console.error("Failed to copy link: ", err);
      });
  };

  return (
    <div className="content-wrapper">
      <h4 className="text-center">Copy and Share this referral link</h4>
      {link ? (
        <button
          onClick={handleCopyLink}
          className="btn btn-link text-left theme-color-bg"
        >
          {link}
        </button>
      ) : (
        <p className="text-center">Loading link...</p>
      )}

      <h4 className="text-center mt-5">Leads List</h4>
      {loading ? (
        <p className="text-center">Loading leads...</p>
      ) : (
        <table className="table table-bordered mt-4">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile Number</th>
              <th>City</th>
            </tr>
          </thead>
          <tbody>
            {leads.length > 0 ? (
              leads.map((lead, index) => (
                <tr key={index}>
                  <td>{lead.name}</td>
                  <td>{lead.email}</td>
                  <td>{lead.mobile}</td>
                  <td>{lead.city}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  No leads available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <ToastContainer />
    </div>
  );
}
