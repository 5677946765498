import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  SEND_REGISTRATION_CUSTOMER,
  CUSTOMER_ADMIN_LIST,
} from "../../Config/api";

export default function AddCustomerSubadmin() {
  const [formData, setFormData] = useState({
    email: "",
    company_id: "",
    name: "",
  });
  const [loading, setLoading] = useState(false);
  const [customersList, setCustomersList] = useState([]);

  useEffect(() => {
    const company_id = localStorage.getItem("company_id");
    if (company_id) {
      setFormData((prevData) => ({
        ...prevData,
        company_id: company_id,
      }));
    } else {
      toast.error("Company ID not found in local storage");
    }
  }, []);

  useEffect(() => {
    fetchAllCustomers();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    const accessToken = localStorage.getItem("accessToken");
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(SEND_REGISTRATION_CUSTOMER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Email sent successfully!");
        window.location.reload()
      } else {
        toast.error("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending customer link:", error);
      toast.error("Error occurred while sending email");
    } finally {
      setLoading(false);
    }
  };

  const fetchAllCustomers = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await fetch(CUSTOMER_ADMIN_LIST, requestOptions);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setCustomersList(data.customers);
      } else {
        console.error("Failed to fetch customers:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

 


  // sorting
  const [searchQuery, setSearchQuery] = useState("");
  const [sortPlatform, setSortPlatform] = useState("");
  const [sortPlan, setSortPlan] = useState("");
  const [sortStatus, setSortStatus] = useState("");

  const filteredCustomers = customersList
    .filter((customer) =>
      customer.customer_name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter((customer) =>
      sortPlatform ? customer.customer_platformType === sortPlatform : true
    )
    .filter((customer) => (sortPlan ? customer.plan === sortPlan : true))
    .filter((customer) =>
      sortStatus ? customer.customer_status === sortStatus : true
    );

  return (
    <div className="content-wrapper">
      <form onSubmit={handleSubmit}>
        <h4 className="mb-3 text-center">Add New Customer</h4>
        <div className="row">
          <div className="form-group my-2 col-md-5">
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Customer Name"
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          <div className="form-group my-2 col-md-5">
            <input
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>

          <div className="form-group my-2 col-md-2">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? "Creating..." : "Send Mail"}
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />

      <div className="my-3">
        <h4 className="mb-3 text-center">All Customers</h4>

        <div className="d-flex mb-3">
          <input
            type="text"
            className="form-control mr-2"
            placeholder="Search by Name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <select
            className="form-control mr-2"
            value={sortPlatform}
            onChange={(e) => setSortPlatform(e.target.value)}
          >
            <option value="">All Platforms</option>
            <option value="AngelOne">AngelOne</option>
            <option value="Upstox">Upstox</option>
          </select>
          <select
            className="form-control mr-2"
            value={sortPlan}
            onChange={(e) => setSortPlan(e.target.value)}
          >
            <option value="">All Plans</option>
            <option value="NOPLAN">No Plan</option>
            <option value="demo">Demo</option>
            <option value="1month">1 Month</option>
            <option value="3month">3 Months</option>
            <option value="6month">6 Months</option>
            <option value="12month">12 Months</option>
          </select>
          <select
            className="form-control"
            value={sortStatus}
            onChange={(e) => setSortStatus(e.target.value)}
          >
            <option value="">Status</option>
            <option value="ACTIVE">Active</option>
            <option value="DEACTIVE">Deactive</option>
          </select>
        </div>

        {customersList.length > 0 ? (
          <div className="customerTable">
            <table className="table text-center">
              <thead>
                <tr>
                  <th>Customer Name</th>
                  <th>Customer Platform</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Plan</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredCustomers.map((customer) => (
                  <tr key={customer.customer_id}>
                    <td>{customer.customer_name}</td>
                    <td>
                    <div className="platform-logo-container">
                          {customer.customer_platformType === "AngelOne" ? (
                            <img
                              className="text-center my-1 platform-logo"
                              src="https://cdn.angelone.in/sparkweb/images/angel-one-logo.png"
                              alt={customer.customer_platformType}
                            />
                          ) : customer.customer_platformType === "Upstox" ? (
                            <img
                              className="text-center my-1 platform-logo"
                              src="https://upstox.com/app/themes/upstox/dist/img/logo/desktop-logo.svg"
                              alt={customer.customer_platformType}
                            />
                          ) : (
                            customer.customer_platformType
                          )}
                        </div>
                    </td>
                    <td>{customer.customer_email}</td>
                    <td>{customer.mobile}</td>
                  <td>{customer.customer_platformType}</td>
                    <td>
                      <button
                        className={
                          customer.customer_status === "ACTIVE"
                            ? "btn btn-success w-100"
                            : "btn btn-danger w-100"
                        }
                      >
                        {customer.customer_status}
                      </button>
                    </td>
          
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No customers found</p>
        )}
      </div>
    </div>
  );
}
