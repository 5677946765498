import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const Graph = () => {
  const data = [
    { x: new Date(1538778600000), y: 6633.33 },
    { x: new Date(1538780400000), y: 6630.11 },
    { x: new Date(1538782200000), y: 6635.65 },
    { x: new Date(1538784000000), y: 6638.24 },
    { x: new Date(1538785800000), y: 6624.47 },
    { x: new Date(1538787600000), y: 6624.31 },
    { x: new Date(1538789400000), y: 6626.02 },
    { x: new Date(1538791200000), y: 6603.02 },
    { x: new Date(1538793000000), y: 6604.01 },
    { x: new Date(1538794800000), y: 6608.02 },
    { x: new Date(1538799000000), y: 6638.24 },
    { x: new Date(1538800000000), y: 6640.24 },
    { x: new Date(1538801000000), y: 6639.33 },
  ];

  const openingPrice = 6640;

  const [series] = useState([
    {
      name: "Price",
      data: data,
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: false, // Hide background grid
    },
    title: {
      text: "Stock Market Price Movement",
      align: "left",
    },
    xaxis: {
      type: "datetime",
      labels: {
        show: false, // Hide x-axis labels
      },
      tooltip: {
        enabled: false, // Disable x-axis tooltip
      },
    },
    yaxis: {
      labels: {
        show: false, // Hide y-axis labels
      },
      tooltip: {
        enabled: false, // Disable y-axis tooltip
      },
    },
    stroke: {
      curve: "smooth",
      colors: ["#4CAF50"],
      width: 2,
    },
    tooltip: {
      enabled: true,
      shared: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        var date = new Date(
          w.globals.seriesX[seriesIndex][dataPointIndex]
        ).toLocaleString();
        var price = series[seriesIndex][dataPointIndex];
        return (
          '<div class="apexcharts-tooltip-custom">' +
          "<div>Date: <span>" +
          date +
          "</span></div>" +
          "<div>Price: <span>" +
          price +
          "</span></div>" +
          "</div>"
        );
      },
    },
    markers: {
      size: 0,
    },
    annotations: {
      yaxis: [
        {
          y: openingPrice,
          borderColor: "blue",
          label: {
            borderColor: "blue",
            style: {
              color: "#fff",
              background: "blue",
            },
            text: openingPrice,
          },
        },
      ],
    },
  });

  //   useEffect(() => {
  //     const updatedSeries = [
  //       {
  //         ...series[0],
  //         data: data.map(() => ({
  //           color: data[data.length-1].y > openingPrice ? "#fff" : "#F44336", //white red
  //         })),
  //       },
  //     ];
  //     setOptions((prevOptions) => ({
  //       ...prevOptions,
  //       stroke: {
  //         ...prevOptions.stroke,
  //         colors: updatedSeries[0].data.map((point) => point.color),
  //       },
  //     }));
  //   }, [data, openingPrice]);
  // Function to compare two objects deeply
  const isEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        (areObjects && !isEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }

    return true;
  };

  // Function to check if a value is an object
  const isObject = (obj) => {
    return obj != null && typeof obj === "object";
  };

  useEffect(() => {
    const updatedSeries = [
      {
        ...series[0],
        data: data.map(() => ({
          color: data[data.length - 1].y > openingPrice ? "#4CAF50" : "#F44336",
        })),
      },
    ];

    const newOptions = {
      ...options,
      stroke: {
        ...options.stroke,
        colors: updatedSeries[0].data.map((point) => point.color),
      },
    };

    // Compare newOptions with previous options and update only if they are different
    if (!isEqual(newOptions, options)) {
      setOptions(newOptions);
    }
  }, [data, openingPrice, options, series]);

  return (
    <div className="content-wrapper">
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default Graph;
