import React, { useState, useEffect } from "react";
import { CREATE_COMPANY_SUBADMIN, GET_COMPANY_SUBADMIN } from "../../Config/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { enforceMaxLength } from "../../Config/utility";

export default function SubAdminEmployee() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    employee_name: "",
    mobile: "",
    user_name: "",
    super_admin: "1",
  });

  const [loading, setLoading] = useState(false); // State to manage loading status

  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    fetchAllCompanies();
  }, []);

  const fetchAllCompanies = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await fetch(GET_COMPANY_SUBADMIN, requestOptions);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setCompanies(data);
      } else {
        console.error("Failed to fetch companies:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting

    const accessToken = localStorage.getItem("accessToken");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(formData),
    };

    try {
      const response = await fetch(CREATE_COMPANY_SUBADMIN, requestOptions);
      const data = await response.json();
      if (response.ok) {
        console.log("Employee created successfully!");
        toast("Employee created successfully!");
        fetchAllCompanies();
        setFormData({
          email: "",
          password: "",
          employee_name: "",
          mobile: "",
          user_name: "",
          super_admin: "1",
        });
      } else {
        // Handle error
        console.error("Failed to create company:", response.statusText);
        if (data.email) {
          toast.error(data.email[0].toUpperCase());
        }
        if (data.user_name) {
          toast.error(data.user_name[0].toUpperCase());
        }
        if (data.mobile) {
          toast.error(data.mobile[0].toUpperCase());
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Set loading back to false after submission completes
    }
  };

  return (
    <div className="content-wrapper">
      <ToastContainer />
      <form onSubmit={handleSubmit}>
      <h4 className="mb-3 text-center">Register New Subadmin</h4>
        <div className="row">
          {/* Input fields */}
          {/* Input fields */}
          <div className="form-group my-2 col-md-4">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          {/* Other input fields */}
          <div className="form-group my-2 col-md-4">
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>

          {/* Other input fields */}
          <div className="form-group my-2 col-md-4">
            <input
              type="text"
              name="employee_name"
              placeholder="Employee Name"
              value={formData.employee_name}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          {/* Other input fields */}
          <div className="form-group my-2 col-md-4">
            <input
              type="number"
              name="mobile"
              placeholder="Mobile"
              value={formData.mobile}
              onChange={handleChange}
              className="form-control"
              required
              onInput={enforceMaxLength}
            />
          </div>
          {/* Other input fields */}
          <div className="form-group my-2 col-md-4">
            <input
              type="text"
              name="user_name"
              placeholder="User Name"
              value={formData.user_name}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          {/* Other input fields */}
          <div className="form-group my-2 col-md-4" hidden>
            <input
              type="text"
              name="super_admin"
              placeholder="Super Admin"
              value={formData.super_admin}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          {/* Submit button */}
          <div className="form-group my-2 col-md-4">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? "Creating..." : "Create Employee"}
            </button>
          </div>
        </div>
      </form>

      <hr />

      {/* Company table */}
      <div className="text-center">
        <h4>Registered Subadmin</h4>

        {companies.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                {/* <th>Company ID</th> */}
                <th>Employee Name</th>
                <th>Employee Username</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Role</th>
                <th>Action</th>
                {/* <th>Created</th> */}
                {/* Add more columns as needed */}
              </tr>
            </thead>
            <tbody>
              {companies.map((company) => (
                <tr key={company.subadmin_id}>
                  {/* <td>{company.company_id}</td> */}
                  <td>{company.employee_name}</td>
                  <td>{company.user_name}</td>
                  <td>{company.email}</td>
                  <td>{company.mobile}</td>
                  <td>{company.role}</td>
                  <td>
                    <div className="d-flex"></div>
                  </td>
                  {/* <td>{company.created_at}</td> */}
                  {/* Add more columns as needed */}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          companies.message
        )}
      </div>
    </div>
  );
}
