import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  COMPANY_BY_ID,
  SHOW_NUMBER_SUPERAADMIN_BY_COMPANY,
  IMG_URL,
} from "../Config/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chart from "react-apexcharts";

export default function CompanyHistory() {
  const { id } = useParams();
  const [companyData, setCompanyData] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showDataTable, setShowDataTable] = useState(true);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await fetch(COMPANY_BY_ID.replace(":id", id), {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setCompanyData(data);
        } else {
          console.error("Failed to fetch company data:", response.statusText);
          toast.error("Failed to fetch company data");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("An error occurred while fetching company data");
      } finally {
        setLoading(false);
      }
    };

    const fetchDashboardData = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await fetch(
          SHOW_NUMBER_SUPERAADMIN_BY_COMPANY.replace(":id", id),
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setDashboardData(data);
        } else {
          console.error("Failed to fetch dashboard data:", response.statusText);
          toast.error("Failed to fetch dashboard data");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("An error occurred while fetching dashboard data");
      }
    };

    fetchCompanyData();
    fetchDashboardData();
  }, [id]);

  const showCustomer = () => {
    setShowDataTable(true);
  };

  const showEmployee = () => {
    setShowDataTable(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!companyData) {
    return <div>No company data found</div>;
  }

  if (!dashboardData) {
    return <div>No dashboard data found</div>;
  }

  const pieChartOptions = {
    labels: ["No Plan", "1 Month", "3 Month", "6 Month", "12 Month"],
  };

  const pieChartSeries = [
    dashboardData.total_NoPlan_customer,
    dashboardData.total_1month_customer,
    dashboardData.total_3month_customer,
    dashboardData.total_6month_customer,
    dashboardData.total_12month_customer,
  ];

  const donutChartOptions = {
    labels: ["Active Customers", "Deactive Customers"],
    colors: ["#439046", "#9f1926"], // Green for active, Red for deactive
  };

  const donutChartSeries = [
    dashboardData.total_active_customer,
    dashboardData.total_deactive_customer,
  ];



  return (
    <div className="content-wrapper">
      <ToastContainer />
      <div className="row mb-3 history-container">
        {companyData.logo ? (
          <div className="col-md-2 text-center">
            <img
              className="logo-img-company"
              src={IMG_URL + companyData.logo}
              alt={companyData.company_name}
            />
          </div>
        ) : (
          ""
        )}
        <div className="col-md-10">
          <h6 className="btn btn-dark white-text mx-2">
            Company: {companyData.company_name}
          </h6>
          <h6 className="btn btn-dark white-text mx-2">
            Owner: {companyData.owner_name}
          </h6>
          <h6 className="btn btn-dark white-text mx-2">
            Mobile: {companyData.mobile}
          </h6>
          <h6 className="btn btn-dark white-text mx-2">
            Email: {companyData.email}
          </h6>
        </div>
      </div>

      <div className="conatiner-fluid my-3">
        <div className="card mt-3">
          <div className="card-content">
            <div className="row row-group m-0 dashboard-tiles-container">
              <div className="col-12 col-lg-4 col-xl-3 border-light">
                <div className="card-body">
                  <h3 className="text-white mb-0 price-stock">
                    {dashboardData.total_customers}
                  </h3>
                  <h5 className="mb-0 text-white small-font">
                    Total Customers
                  </h5>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-xl-3 border-light">
                <div className="card-body">
                  <h3 className="text-white mb-0 price-stock">
                    {dashboardData.total_order_history}
                  </h3>
                  <h5 className="mb-0 text-white small-font">Total Trades</h5>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-xl-3 border-light">
                <div className="card-body">
                  <h3 className="text-white mb-0 price-stock">
                    {dashboardData.total_employee}
                  </h3>
                  <h5 className="mb-0 text-white small-font">
                    Total Employees
                  </h5>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-xl-3 border-light">
                <div className="card-body">
                  <h3 className="text-white mb-0 price-stock">
                    {dashboardData.total_active_customer}
                  </h3>
                  <h5 className="mb-0 text-white small-font">
                    Active Customers
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row my-3 graphs-container">
            <div className="col-12 col-lg-6 w-100">
              <h4 className="text-center">Customer Plans</h4>
              <div className="card mt-3">
                <div className="card-content">
                  <Chart
                    options={pieChartOptions}
                    series={pieChartSeries}
                    type="pie"
                    height="350"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 w-100">
              <h4 className="text-center">Customer Status</h4>
              <div className="card mt-3">
                <div className="card-content">
                  <Chart
                    options={donutChartOptions}
                    series={donutChartSeries}
                    type="donut"
                    height="350"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mx-2 mb-3">
        <div className="col-md-6">
          <button
            className={`btn btn-dark white-text w-100 ${
              !showDataTable ? "show-active" : ""
            }`}
            onClick={() => showEmployee()}
          >
            Employees: {companyData.employees.length}
          </button>
        </div>
        <div className="col-md-6">
          <button
            className={`btn btn-dark white-text w-100 ${
              showDataTable ? "show-active" : ""
            }`}
            onClick={() => showCustomer()}
          >
            Customers: {companyData.customers.length}
          </button>
        </div>
      </div>

      {!showDataTable && companyData.employees.length > 0 ? (
        <table className="table text-center">
          <thead>
            <tr>
              <th>Employee Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {companyData.employees.map((employee) => (
              <tr key={employee.id}>
                <td>{employee.employee_name}</td>
                <td>{employee.email}</td>
                <td>{employee.mobile}</td>
                <td>{employee.role}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        ""
      )}

      {showDataTable && companyData.customers.length > 0 ? (
        <table className="table text-center">
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Status</th>
              <th>Plan</th>
            </tr>
          </thead>
          <tbody>
            {companyData.customers
              .sort((a, b) => (a.customer_status === "ACTIVE" ? -1 : 1))
              .map((customer) => (
                <tr key={customer.id}>
                  <td>{customer.customer_name}</td>
                  <td>{customer.customer_email}</td>
                  <td>{customer.mobile}</td>
                  <td>
                    <button
                      className={
                        customer.customer_status === "ACTIVE"
                          ? "btn btn-success w-100"
                          : "btn btn-danger w-100"
                      }
                    >
                      {customer.customer_status}
                    </button>
                  </td>
                  <td>{customer.plan}</td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        ""
      )}
    </div>
  );
}
