import React, { useState, useEffect } from "react";
import { LOGIN_URL } from "../Config/api";
import { enforceNoSpaces } from "../Config/utility";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginRole, setLoginRole] = useState("");
  const isValidEmail = (email) => {
    // Regular expression for validating email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignIn = async () => {
    setError(null); // Clear previous errors
    if (!isValidEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }
    if (!password.trim()) {
      setError("Please enter your password.");
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(LOGIN_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log(response.ok);
        console.log(data);
        console.log(data.access);
        console.log(data.role);
        localStorage.setItem("accessToken", data.access);
        localStorage.setItem("refreshToken", data.refresh);
        localStorage.setItem("role", data.role);
        localStorage.setItem("company_id", data.company_id);
        setLoggedIn(true);
        setLoginRole(data.role);
      } else {
        console.log(data);
        setError(data.non_field_errors || "Login failed");
      }
    } catch (error) {
      console.error("Error occurred while logging in:", error);
      setError("An error occurred while logging in.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      setLoggedIn(true);
    }
  }, []);

  if (loggedIn && loginRole === "superadmin") {
    return (window.location.href = "/dashboard");
    // return <Navigate to="/dashboard" />;
  } else if (loggedIn && loginRole === "company") {
    return (window.location.href = "/company-data");
    // return <Navigate to="/company-data" />;
  } else if (loggedIn && loginRole === "employee") {
    return (window.location.href = "/employee-data");
  } else if (loggedIn && loginRole === "subadmin") {
    // return (window.location.href = "/subadmin");
    return (window.location.href = "/subadmin/add-customer");
  }

  return (
    <div className="login-bg" id="wrapper">
      <div className="card card-authentication1 mx-auto">
        <div className="card-body">
          <div className="card-content p-2">
            <div className="text-center">
              {/* <img src="assets/images/logo-icon.png" alt="logo icon" /> */}
              <img
                className="img-logo"
                src="https://affyclouditsolutions.com/static/media/affy_logo.7354960bda008279c013.png"
                alt="logo icon"
              />
            </div>
            <div className="card-title text-uppercase text-center py-3">
              Sign In
            </div>
            <form>
              <div className="form-group">
                <label htmlFor="exampleInputEmail" className="sr-only">
                  Email
                </label>
                <div className="position-relative has-icon-right">
                  <input
                    type="email"
                    id="exampleInputEmail"
                    className="form-control input-shadow"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onInput={enforceNoSpaces}
                    required
                  />
                  <div className="form-control-position">
                    <i className="icon-user" />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword" className="sr-only">
                  Password
                </label>
                <div className="position-relative has-icon-right">
                  <input
                    type="password"
                    id="exampleInputPassword"
                    className="form-control input-shadow"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onInput={enforceNoSpaces}
                  />
                  <div className="form-control-position">
                    <i className="icon-lock" />
                  </div>
                </div>
              </div>
              {error && <div className="text-danger">{error}</div>}
              <button
                type="submit"
                className="btn btn-light btn-block"
                onClick={handleSignIn}
                disabled={loading}
              >
                {loading ? "Signing In..." : "Sign In"}
              </button>
              <div className="form-row mt-3">
                <div className="form-group col-6">
                  <div className="icheck-material-white">
                    <input type="checkbox" id="user-checkbox" defaultChecked />
                    <label htmlFor="user-checkbox">Remember me</label>
                  </div>
                </div>
                {/* <div className="form-group col-6 text-right">
                  <button className="btn btn-danger" >Reset Password</button>
                </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
