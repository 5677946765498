import React, { useState, useEffect, useContext, useRef } from "react";
import { ProfileContext } from "../Pages/ProfileContext";
import { Link, Navigate } from "react-router-dom";
import { enforceNoSpaces } from "../Config/utility";
import axios from "axios";
const Navbar = ({ toggleSidebar }) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [activatePrice, setActivatePrice] = useState(true);
  const [apiKey, setApiKey] = useState('');
  const [password, setPassword] = useState('');
  const [totp, setTotp] = useState('');
  const [client_code, setClientCode] = useState('');
  useEffect(() => {
    const storedDate = localStorage.getItem("activateDate");
    const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

    if (storedDate && storedDate === today) {
      setActivatePrice(false);
    } else {
      setActivatePrice(true);
    }
  }, []);

  useEffect(() => {
    if (activatePrice) {
      const modalElement = document.getElementById("activateModal");
      if (modalElement) {
        const modal = new window.bootstrap.Modal(modalElement);
        setTimeout(() => {
          modal.show();
        }, 0);
      }
    }
  }, [activatePrice]);

  useEffect(() => {
    loadResults();
  }, [searchValue]); // Reload results when search value changes

  function loadResults() {
    if (loadingData) return; // Prevent multiple simultaneous requests
    setLoadingData(true);

    // Fetch search results from the server
    fetch(
      `https://margincalculator.angelbroking.com/OpenAPI_File/files/OpenAPIScripMaster.json`
    )
      .then((response) => response.json())
      .then((data) => {
        // Filter the data based on search value and instrumenttype
        // const filteredResults = data.filter(
        //   (item) =>
        //     // item.instrumenttype === "AMXIDX" &&
        //     item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        //     item.symbol.toLowerCase().includes(searchValue.toLowerCase())
        // );

        const filteredResults = data.filter(
          (item) =>
            (item.instrumenttype === "AMXIDX" || item.symbol.endsWith("-EQ")) &&
            (item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
              item.symbol.toLowerCase().includes(searchValue.toLowerCase()))
        );

        const totalPagesCount = Math.ceil(filteredResults.length / 100);
        setTotalPages(totalPagesCount);

        if (currentPage === 1) {
          setSearchResults([]); // Clear previous results if it's the first page
        }

        const start = (currentPage - 1) * 100;
        const end = currentPage * 100;
        const resultsToDisplay = filteredResults.slice(start, end);

        setSearchResults((prevResults) => [
          ...prevResults,
          ...resultsToDisplay,
        ]);

        setLoadingData(false); // Reset loadingData flag
      })
      .catch((error) => {
        console.error("Error fetching search results:", error);
        setLoadingData(false); // Reset loadingData flag
      });
  }

  function handleInputChange(event) {
    setSearchValue(event.target.value);
    setCurrentPage(1); // Reset to first page when performing a new search
  }

  function handleScroll() {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      if (currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [currentPage, totalPages]); // Add scroll event listener when component mounts/unmounts

  useEffect(() => {
    loadResults();
  }, [currentPage]);

  const showData = (token, exch_seg, name) => {
    // window.location.href = `/employee-data/candlegraph/${token}`;
    window.location.href = `/employee-data/candlegraph/${token}/${exch_seg}`;
    // return <Navigate to={`/employee-data/candlegraph/${token}/${exch_seg}/${name}`} />;
  };

  const savedRoles = localStorage.getItem("role");

  const { profile, loading, error, handleLogout } = useContext(ProfileContext);

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((err) => {
        alert(
          `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
        );
      });
    } else {
      document.exitFullscreen();
    }
  };

  const handleActivatePrice = (event) => {
    event.preventDefault();

    const data = {
      clientcode: client_code,
      password,
      totp,
    };

    axios({
      method: "post",
      url: "https://apiconnect.angelbroking.com/rest/auth/angelbroking/user/v1/loginByPassword",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-UserType": "USER",
        "X-SourceID": "WEB",
        "X-ClientLocalIP": "192.168.168.168",
        "X-ClientPublicIP": "106.193.147.98",
        "X-MACAddress": "fe80::216e:6507:4b90:3719",
        "X-PrivateKey": apiKey,
      },
      data: JSON.stringify(data),
    })
      .then((response) => {
        console.log(response.data);
        if (response.status) {
          const reqData = response.data.data;
          const today = new Date().toISOString().split("T")[0];
          localStorage.setItem("activateDate", today);
          localStorage.setItem("angelFeedToken", reqData.feedToken);
          localStorage.setItem("feedToken", reqData.feedToken);
          localStorage.setItem("angelToken", reqData.jwtToken);
          localStorage.setItem("dataApi", apiKey);
          setActivatePrice(false);
          window.location.reload(); // Refresh the page
      }
      
      })
      .catch((error) => {
        console.error("Error activating price:", error);
      });
  };

  return (
    <header className="topbar-nav">
      <nav className="navbar navbar-expand fixed-top">
        <ul className="navbar-nav mr-auto align-items-center">
          <li className="nav-item">
            <a
              className="nav-link toggle-menu"
              href="#"
              onClick={toggleSidebar}
            >
              <i className="icon-menu menu-icon"></i>
            </a>
          </li>
          {savedRoles === "employee" ? (
            <>
              <li className="nav-item">
                <form className="search-bar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Index"
                    value={searchValue}
                    onChange={handleInputChange}
                  />
                  {searchValue && (
                    <div className="token-seach-result">
                      {searchResults.length > 0 ? (
                        searchResults.map((item, index) => (
                          <div key={index} className="result">
                            <p
                              className=""
                              onClick={() =>
                                showData(item.token, item.exch_seg, item.name)
                              }
                            >
                              {item.name} || {item.symbol}
                            </p>
                          </div>
                        ))
                      ) : (
                        <div className="result">
                          <p>No Data</p>
                        </div>
                      )}
                    </div>
                  )}
                </form>
              </li>
              <li className="nav-item">
                {activatePrice && (
                  <button
                    className="nav-link btn btn-secondary mx-2"
                    data-toggle="modal"
                    data-target="#activateModal"
                  >
                    Activate Live Data
                  </button>
                )}
              </li>
            </>
          ) : (
            ""
          )}
        </ul>
        <ul className="navbar-nav align-items-center right-nav-link">
          <li
            className="dropdown-item fullscreen-btn"
            onClick={() => handleFullscreen()}
          >
            <i className="fa fa-arrows-alt mr-2"></i>
          </li>
        </ul>

        <ul className="navbar-nav align-items-center right-nav-link">
          <li className="nav-item">
            <a
              className="nav-link dropdown-toggle dropdown-toggle-nocaret"
              data-toggle="dropdown"
              href="#"
            >
              <span className="user-profile">
              <i className="fa fa-user text-white" />
                {/* <img
                  src="https://via.placeholder.com/110x110"
                  className="img-circle"
                  alt="user avatar"
                /> */}
              </span>
            </a>
            <ul className="dropdown-menu dropdown-menu-right">
              <li className="dropdown-item user-details">
                <a href="javaScript:void();">
                  <div className="media">
                    {/* <div className="avatar">
                      <img
                        className="align-self-start mr-3"
                        src="https://via.placeholder.com/110x110"
                        alt="user avatar"
                      />
                    </div> */}
                    <div className="media-body">
                      <h6 className="mt-2 user-title">
                        {savedRoles == "employee" || savedRoles == "subadmin"
                          ? profile.employee_name
                          : profile.owner_name}
                      </h6>
                      {/* <h6 className="mt-2 user-title">{profile.owner_name}</h6> */}
                      {/* <p className="user-subtitle">{profile.email}</p> */}
                    </div>
                  </div>
                </a>
              </li>
              <li className="dropdown-divider"></li>
              {/* <li className="dropdown-item">
                <i className="icon-envelope mr-2"></i> Inbox
              </li>
              <li className="dropdown-divider"></li> */}
              {/* <li className="dropdown-item">
                <i className="icon-wallet mr-2"></i> Account
              </li> */}
              {/* <li className="dropdown-divider"></li>
              <li className="dropdown-item">
                <i className="icon-settings mr-2"></i> Setting
              </li> */}
              <li className="dropdown-divider"></li>
              <li className="dropdown-item" onClick={handleLogout}>
                <i className="icon-power mr-2"></i> Logout
              </li>
            </ul>
          </li>
        </ul>
      </nav>

      {/* Bootstrap Modal */}
      <div
        className="modal fade"
        id="activateModal"
        tabIndex="-1"
        aria-labelledby="activateModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="activateModalLabel">
                Activate Live Data
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleActivatePrice}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter API Key"
                    value={apiKey}
                    onInput={enforceNoSpaces}
                    onChange={(e) => setApiKey(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Client Code"
                    value={client_code}
                    onInput={enforceNoSpaces}
                    onChange={(e) => setClientCode(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    value={password}
                    onInput={enforceNoSpaces}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="TOTP"
                    value={totp}
                    onInput={enforceNoSpaces}
                    onChange={(e) => setTotp(e.target.value)}
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
