import React, { useEffect, useState, useRef } from "react";
import {
  COMPANY_PROFILE,
  IMG_URL,
  COMPANY_PROFILE_UPDATE,
  COMPANY_PASSWORD_UPDATE,
  COMPANY_LOGO_UPDATE,
} from "../../Config/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export default function CompanyProfile() {
  const [profileData, setProfileData] = useState({
    owner_name: "",
    logo: "",
    company_name: "",
    mobile: "",
    user_name: "",
  });
  const [isEditing, setIsEditing] = useState(false);

  const profileCheck = () =>{
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      fetch(COMPANY_PROFILE, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setProfileData(data);
        })
        .catch((error) => console.error("Error fetching profile data:", error));
    }
  }

  useEffect(() => {
    profileCheck()
  }, []);

  const handleSaveChanges = () => {
    const { owner_name, company_name, mobile } = profileData;

    // Check for empty fields
    if (!owner_name || !company_name || !mobile) {
      toast.error("All fields must be filled out");
      return;
    }

    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      const updatedData = {
        owner_name,
        company_name,
        mobile,
      };

      fetch(COMPANY_PROFILE_UPDATE, {
        method: "PUT", // Assuming PUT method for update
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(updatedData),
      })
        .then((response) => response.json())
        .then((data) => {
          setProfileData((prevState) => ({
            ...prevState,
            ...data,
          }));
          setIsEditing(false);
          toast.success("Updated Successfully");
          profileCheck()
        })
        .catch((error) => {
          console.error("Error saving profile data:", error);
          if (error.response) {
            console.log("Response data:", error.response.data);
            console.log("Response status:", error.response.status);
          }
        });
    }
  };

  const [form, setForm] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [showPassword, setShowPassword] = useState({
    old_password: false,
    new_password: false,
    confirm_password: false,
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setForm({
      ...form,
      [id]: value,
    });
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevShowPassword) => ({
      ...prevShowPassword,
      [field]: !prevShowPassword[field],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { old_password, new_password, confirm_password } = form;

    if (new_password !== confirm_password) {
      toast.error("New Password and Confirm New Password do not match!");
      return;
    }

    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        COMPANY_PASSWORD_UPDATE,
        {
          old_password,
          new_password,
          confirm_password,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      toast.success(response.data.detail);
      setForm({
        old_password: "",
        new_password: "",
        confirm_password: "",
      });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.detail);
      } else {
        toast.error("An error occurred while updating the password.");
      }
    }
  };
  const fileInputRef = useRef(null);
  const updateLogo = () => {
    const fileInput = document.getElementById("logo-upload");
    fileInput.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!["image/jpeg", "image/png"].includes(file.type)) {
      alert("Please upload a JPEG or PNG image.");
      return;
    }

    const accessToken = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("logo", file);

    try {
      const response = await fetch(COMPANY_LOGO_UPDATE, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (response.ok) {
        // Handle successful response
        toast.success("Logo updated successfully");
        profileCheck()
        window.location.reload()
      } else {
        // Handle errors
        console.error("Error updating logo");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="content-wrapper">
      <ToastContainer />
      <div className="container">
        <div className="d-flex justify-content-space-between align-items-center">
          <div className="form-group my-2 text-center">
            {profileData.logo ? (
              <>
                <img
                  className="logo-img-company"
                  src={IMG_URL + profileData.logo}
                  alt="Company Logo"
                />
              </>
            ) : (
              ""
            )}
            <div className="my-2">
              <input
                id="logo-upload"
                type="file"
                accept="image/jpeg, image/png"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              <button
                className="btn btn-primary mx-1"
                onClick={() => updateLogo()}
              >
                {profileData.logo ? "Edit Logo" : "Upload Logo"}
              </button>
            </div>
          </div>
          <div>
            {isEditing ? (
              <button
                className="btn btn-primary mx-1"
                onClick={handleSaveChanges}
              >
                Save Changes
              </button>
            ) : (
              <button
                className="btn btn-secondary mx-1"
                onClick={() => setIsEditing(true)}
              >
                Edit
              </button>
            )}
            <button
              className="btn btn-danger mx-1"
              data-toggle="modal"
              data-target="#changePassword"
            >
              Change Password
            </button>

            <div
              class="modal fade"
              id="changePassword"
              tabindex="-1"
              role="dialog"
              aria-labelledby="changePasswordLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="changePasswordLabel">
                      Change Password
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <form
                      className="row profileContainer change-password-container"
                      onSubmit={handleSubmit}
                    >
                      <div className="form-group col-md-12">
                        <label htmlFor="old_password">Current Password</label>
                        <div className="input-group">
                          <input
                            type={
                              showPassword.old_password ? "text" : "password"
                            }
                            className="form-control"
                            id="old_password"
                            placeholder="Current Password"
                            value={form.old_password}
                            onChange={handleInputChange}
                          />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={() =>
                                togglePasswordVisibility("old_password")
                              }
                            >
                              <i
                                className={
                                  showPassword.old_password
                                    ? "fa fa-eye-slash"
                                    : "fa fa-eye"
                                }
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="new_password">New Password</label>
                        <div className="input-group">
                          <input
                            type={
                              showPassword.new_password ? "text" : "password"
                            }
                            className="form-control"
                            id="new_password"
                            placeholder="New Password"
                            value={form.new_password}
                            onChange={handleInputChange}
                          />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={() =>
                                togglePasswordVisibility("new_password")
                              }
                            >
                              <i
                                className={
                                  showPassword.new_password
                                    ? "fa fa-eye-slash"
                                    : "fa fa-eye"
                                }
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="confirm_password">
                          Confirm New Password
                        </label>
                        <div className="input-group">
                          <input
                            type={
                              showPassword.confirm_password
                                ? "text"
                                : "password"
                            }
                            className="form-control"
                            id="confirm_password"
                            placeholder="Confirm New Password"
                            value={form.confirm_password}
                            onChange={handleInputChange}
                          />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={() =>
                                togglePasswordVisibility("confirm_password")
                              }
                            >
                              <i
                                className={
                                  showPassword.confirm_password
                                    ? "fa fa-eye-slash"
                                    : "fa fa-eye"
                                }
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        <button type="submit" className="btn btn-primary">
                          Update Password
                        </button>
                      </div>
                    </form>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form className="row profileContainer">
          <div className="form-group my-2 col-md-6">
            <label htmlFor="ownerName">Name</label>
            {isEditing ? (
              <input
                type="text"
                className="form-control"
                id="ownerName"
                value={profileData.owner_name}
                onChange={(e) =>
                  setProfileData({ ...profileData, owner_name: e.target.value })
                }
              />
            ) : (
              <p>{profileData.owner_name}</p>
            )}
          </div>

          <div className="form-group my-2 col-md-6">
            <label htmlFor="companyName">Company Name</label>
            {isEditing ? (
              <input
                type="text"
                className="form-control"
                id="companyName"
                value={profileData.company_name}
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    company_name: e.target.value,
                  })
                }
              />
            ) : (
              <p>{profileData.company_name}</p>
            )}
          </div>

          <div className="form-group my-2 col-md-6">
            <label htmlFor="mobile">Mobile</label>
            {isEditing ? (
              <input
                type="number"
                className="form-control"
                id="mobile"
                value={profileData.mobile}
                readOnly
                onChange={(e) =>
                  setProfileData({ ...profileData, mobile: e.target.value })
                }
              />
            ) : (
              <p>{profileData.mobile}</p>
            )}
          </div>

          <div className="form-group my-2 col-md-6">
            <label htmlFor="userName">Email</label>
            <p>{profileData.email}</p>
          </div>
          <div className="form-group my-2 col-md-6">
            <label htmlFor="userName">Username</label>
            <p>{profileData.user_name}</p>
          </div>
        </form>
      </div>
    </div>
  );
}
