import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ProfileContext } from "../Pages/ProfileContext";
import { IMG_URL } from "../Config/api";

const Sidebar = () => {
  const [userRole, setUserRole] = useState("");
  useEffect(() => {
    const role = localStorage.getItem("role");
    setUserRole(role);
  }, []);

  const menuOptions = {
    superadmin: [
      { text: "Dashboard", path: "/dashboard", icon: "zmdi-view-dashboard" },
      { text: "Company", path: "/company", icon: "zmdi-comments" },
      // { text: "Employee", path: "/employee" },
      { text: "Profile", path: "/profile", icon: "zmdi-face" },
    ],
    company: [
      { text: "Dashboard", path: "/company-data", icon: "zmdi-view-dashboard" },
      {
        text: "Employee",
        path: "/company-data/company-employee",
        icon: "zmdi-accounts-add",
      },
      {
        text: "Customer",
        path: "/company-data/customer",
        icon: "zmdi-accounts-add",
      },
      {
        text: "Subadmin",
        path: "/company-data/subadmin",
        icon: "zmdi-accounts-add",
      },
      {
        text: "Order History",
        path: "/company-data/customer-reports",
        icon: "zmdi-comment-alt-text",
      },
      { text: "Profile", path: "/company-data/profile", icon: "zmdi-account" },
    ],
    employee: [
      {
        text: "Dashboard",
        path: "/employee-data",
        icon: "zmdi-view-dashboard",
      },
      { text: "Group", path: "/employee-data/groups", icon: "zmdi-comments" },
      {
        text: "Customer",
        path: "/employee-data/customer",
        icon: "zmdi-accounts",
      },
      {
        text: "Order History",
        path: "/employee-data/customer-reports",
        icon: "zmdi-comment-alt-text",
      },
      { text: "Profile", path: "/employee-data/profile", icon: "zmdi-account" },
      // { text: "Profile", path: "/profile", icon: "zmdi-face" },
    ],
    subadmin: [
      // {
      //   text: "Dashboard",
      //   path: "/subadmin",
      //   icon: "zmdi-view-dashboard",
      // },
      {
        text: "Subadmin",
        path: "/subadmin/subadmin",
        icon: "zmdi-accounts-add",
      },
      {
        text: "Customer",
        path: "/subadmin/add-customer",
        icon: "zmdi-accounts-add",
      },
      { text: "Profile", path: "/subadmin/profile", icon: "zmdi-account" },
      { text: "Share", path: "/subadmin/share-link", icon: "zmdi-account" },
    ],
  };

  // const { profile, loading, error } = useContext(ProfileContext);
  const { profile } = useContext(ProfileContext);
  const { handleLogout } = useContext(ProfileContext);

  // if (loading) {
  //   return <div></div>;
  // }

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }
  return (
    <div id="sidebar-wrapper" data-simplebar="" data-simplebar-auto-hide="true">
      <div className="brand-logo">
        {/* <a href="#"> */}
        <img
          src={
            profile && profile.logo !== null
              ? IMG_URL + profile.logo
              : "https://affyclouditsolutions.com/static/media/affy_logo.7354960bda008279c013.png"
          }
          className="logo-icon"
          alt="logo icon"
        />

        <h5 className="logo-text">
          {profile ? profile.company_name : "Affy Cloud"}
        </h5>
        {/* </a> */}
      </div>
      <ul className="sidebar-menu do-nicescrol">
        {/* <li>
          <Link to="/">
            <i className="zmdi zmdi-view-dashboard"></i> <span>Dashboard</span>
          </Link>
        </li> */}

        {userRole &&
          menuOptions[userRole]?.map((option, index) => (
            <li key={index}>
              <Link to={option.path}>
                <i className={`zmdi ${option.icon}`}></i>{" "}
                <span>{option.text}</span>
              </Link>
            </li>
          ))}

        <li onClick={handleLogout}>
          <Link>
            <i className="zmdi zmdi-power"></i> <span>Log out</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
